import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import Null_player from "../../../assets/imgs/null-player-img.svg";
import Small_polygon from "../../../assets/imgs/small_polygon.svg";
import { Link } from "react-router-dom";
import { Paragraph } from "../Paragraph";
import { teamColors } from "../../../config/constants/teamColors";

interface TableHeaderItem {
  headerType: string;
  position?: string;
  headers: string[];
}
import { useNavigate } from "react-router-dom";
import { playerProfileCountViews } from "../../../Controllers/PlayerProfileCache";

interface DataItem {
  name: string;
  firstName?: string;
  lastName?: string;
  position: string;
  Opponent?: string;
  consensus?: number | null;
  consensus_pos?: string;
  bye?: number | null;
  team?: string;
  total_ppr_fantasy_points_scored?: number | null;
  FPTS_PPR?: number | null;
  url?: string;
  pass_attempts?: number | null;
  completions?: number | null;
  passing_yards?: number | null;
  passing_tds?: number | null;
  interceptions?: number | null;
  passQBRating?: number | null;
  carries?: number | null;
  rushing_yards?: number | null;
  yards_per_rush_attempt?: number | null;
  rushing_tds?: number | null;
  targets?: number | null;
  receptions?: number | null;
  yards_per_reception?: number | null;
  receiving_tds?: number | null;
  trade_value?: number | null;
  roto_player_id?: string;
  [key: string]: any;
}

interface Category {
  name: string;
}

interface PositionItem {
  id: number;
  name: string;
}

interface Props {
  tableHeader: TableHeaderItem[];
  data: DataItem[];
  category: Category;
  positionSelected: PositionItem;
}

const TableCom: React.FC<Props> = ({
  tableHeader,
  data,
  category,
  positionSelected,
}) => {
  const findHeadersByTypeAndPosition = (headerType: string, position: string) => {
    if (position === "All") {
      return tableHeader.find((element) => element.headerType === headerType);
    }
    const headers = tableHeader.find(
      (element) =>
        element.headerType === headerType && element.position === position
    );
    return headers
      ? headers
      : tableHeader.find((element) => element.headerType === headerType);
  };

  const specificHeaders =
    category.name === "Season Leaders" || category.name === "This Week"
      ? findHeadersByTypeAndPosition(category.name, positionSelected.name || "")
      : findHeadersByTypeAndPosition(category.name, "");
  const navigate = useNavigate();

  console.log("Specific Headers: ", specificHeaders);
  console.log("Data: ", data);

  const fetchFieldName = (item: DataItem) => {
    // if (category.name === "This Week" || category.name === "ROS Rank") {
    //   return item.Name ? `${item.Name}` : null;
    // }
    // return item.firstName && item.lastName
    //   ? `${item.firstName} ${item.lastName}`
    //   : null;
    return item.name ? item.name : null;
  };

  const fetchFieldValue = (item: DataItem, header: string) => {
    console.log("Item: ", item, "Header: ", header);
    switch (header) {
      case "FPTS":
        return item.FPTS_PPR != null
          ? item.FPTS_PPR.toFixed(2)
          : item.total_ppr_fantasy_points_scored != null
            ? item.total_ppr_fantasy_points_scored?.toFixed(2)
            : "-";
      case "Pass Attempts":
        return item.pass_attempts != null ? item.pass_attempts : "-";
      case "Completions":
        return item.completions != null ? item.completions : "-";
      case "Yards":
        return item.passing_yards != null ? item.passing_yards : "-";
      case "TDs":
        return item.passing_tds != null ? item.passing_tds : "-";
      case "INT":
        return item.interceptions != null ? item.interceptions : "-";
      case "Passer Rating":
        return item.passQBRating != null ? item.passQBRating : "-";
      case "Rushing Attempts":
        return item.carries != null ? item.carries : "-";
      case "Rushing Yards":
        return item.rushing_yards != null ? item.rushing_yards : "-";
      case "Rushing Yards per Carry":
        return item.yards_per_rush_attempt != null
          ? item.yards_per_rush_attempt?.toFixed(2)
          : "-";
      case "Rushing TDs":
        return item.rushing_tds != null ? item.rushing_tds : "-";
      case "Targets":
        return item.targets != null && item.targets !== -1 ? item.targets : "-";
      case "Receptions":
        return item.receptions != null && item.receptions !== -1
          ? item.receptions
          : "-";
      case "Yards per Reception":
        return item.yards_per_reception != null &&
          item.yards_per_reception !== -1
          ? item.yards_per_reception.toFixed(2)
          : "-";
      case "Receiving TDs":
        return item.receiving_tds != null && item.receiving_tds !== -1
          ? item.receiving_tds
          : "-";
      case "Trade Value":
        return item.trade_value != null ? item.trade_value : "-";
      case "Consensus":
        return item.consensus_pos != null ? item.consensus_pos : "-";
      case "Consensus Pos":
        return item.consensus_pos != null ? item.consensus_pos : "-";
      case "Bye":
        return item.bye != null ? item.bye : "-";
      case "Team":
        return item.team != null ? item.team : "-";
      case "RK.":
        return item.consensus != null ? item.consensus.toFixed(2) : "-";
      case "Player Name":
        return item.name ? item.name : "-";
      
      default:
        return "-";
    }
  };

  const onClickCell = async (playerId: string) => {
    await playerProfileCountViews(playerId);
    navigate(`/player-profile/${playerId}`);
  };

  return (
    <div className="">
      <Table className=" overflow-x-auto w-full">
        <TableHeader>
          <TableRow className="hover:bg-transparent border-transparent">
            {specificHeaders?.headers &&
              specificHeaders.headers.map((item, index) => (
                <TableHead
                  key={"players-header-" + index}
                  className="text-white text-[14px] pb-2"
                  style={index !== 0 ? { minWidth: "150px" } : {}}
                >
                  {item}
                </TableHead>
              ))}
          </TableRow>
        </TableHeader>
        <TableBody className={`[&>*:nth-child(odd)]:bg-[#FFFFFF1A]`}>
          {data.map((item, index) => (
            <TableRow
              key={"player-body-" + index}
              className={`cursor-pointer hover:bg-transparent border-transparent bg-transparent text-white`}
              onClick={() => onClickCell(item.roto_player_id as string)}
            >
              <TableCell
                key={"player-cell-" + index}
                className="font-medium text-[12px]"
                style={{ width: "100px" }}
              >
                #{index + 1}
              </TableCell>

              {/* hard code bg color headshot */}

              <TableCell
                key={"player-cell-name-" + index}
                className="text-base font-medium flex items-center gap-3"
                style={{ width: "240px" }}
              >
                <div className="relative">
                  <div
                    className="rounded-xl w-[49.2px] h-[49.2px] flex items-end overflow-hidden"
                    style={{
                      backgroundColor: item.Opponent
                        ? teamColors[item.Opponent]
                        : "#4F2683",
                    }}
                  >
                    <img
                      className={item.url ? "scale-[1.8]" : "h-[45px]"}
                      src={item.url || Null_player}
                      alt=""
                    />
                  </div>
                  <div className="absolute -bottom-0 -right-[10px]">
                    <div className="relative">
                      <img
                        src={Small_polygon}
                        alt=""
                        className="w-[20px] h-[20px]"
                      />
                      <Paragraph
                        text={item.position}
                        props={{
                          fontSize: "8px",
                          lineHeight: "100%",
                          fontWeight: "600",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        ParagraphType="Pragragh22"
                        color="textPrimary"
                      />
                    </div>
                  </div>
                </div>
                {fetchFieldName(item)}
              </TableCell>

              {specificHeaders?.headers.slice(2).map((header, idx) => (
                <TableCell
                  key={`cell-${index}-${idx}`}
                  style={{ width: "100px" }} // Adjust width as needed
                >
                  {fetchFieldValue(item, header)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableCom;
