import { Colors } from "../../Commons/Colors/Colors";
import "./loadingAnimation.css";

const LoadingAnimation = () => {
  const containerStyle = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "45px",
    height: "calc(45px * 0.6)",
  };

  const cubeStyle = (delay: any) => ({
    flexShrink: 0,
    width: "calc(45px * 0.2)",
    height: "calc(45px * 0.2)",
    animation: `jump 1.75s ease-in-out infinite ${delay}`,
  });

  const cubeInnerStyle = (delay: any) => ({
    display: "block",
    height: "100%",
    width: "100%",
    borderRadius: "25%",
    backgroundColor: Colors.backgroundTertiary,
    transformOrigin: "center bottom",
    animation: `morph 1.75s ease-in-out infinite ${delay}`,
    transition: "background-color 0.3s ease",
  });

  return (
    <div style={containerStyle}>
      <div style={cubeStyle("0s")}>
        <div style={cubeInnerStyle("0s")}></div>
      </div>
      <div style={cubeStyle("calc(-1.75s * 0.36)")}>
        <div style={cubeInnerStyle("calc(-1.75s * 0.36)")}></div>
      </div>
      <div style={cubeStyle("calc(-1.75s * 0.2)")}>
        <div style={cubeInnerStyle("calc(-1.75s * 0.2)")}></div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
