import PlayerAnalyzer from "../../assets/imgs/player-analyzer.svg";
import DataBaseIcon from "../../assets/imgs/trade-analyzer.svg";
import PeopleComunityIcon from "../../assets/imgs/team-analyzer.svg";
import PersonQuestionIcon from "../../assets/imgs/sit-start-decider.svg";
import VectorIcon from "../../assets/imgs/league-selector.svg";
import WaiverAssistant from "../../assets/imgs/waiver-assistant.svg";

export const ChatbotModesCards = [
    {
        icons: VectorIcon,
        title: "League Selector",
        description: "Detailed player analysis with proprietary metrics and performance outlooks for strategic decisions.",
    },
    {
        icons: PlayerAnalyzer,
        title: "Player Analyzer",
        description: "Detailed player analysis with proprietary metrics and performance outlooks for strategic decisions.",
    },
    {
        icons: DataBaseIcon,
        title: "Trade Analyzer",
        description: "Comprehensive trade analysis with insights and recommendations to optimize your fantasy football trades.",
    },
    {
        icons: PeopleComunityIcon,
        title: "Team Analyzer",
        description: "In-depth team analysis with performance metrics and strategies to enhance your fantasy football team's success.",
    },
    {
        icons: PersonQuestionIcon,
        title: "Sit/Start Decider",
        description: "Expert sit/start recommendations to help you make the best lineup decisions for your team.",
    },
    {
        icons: WaiverAssistant,
        title: "Waiver Assistant",
        description: "Expert sit/start recommendations to help you make the best lineup decisions for your team.",
    },
]