import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { persist } from 'zustand/middleware';
import create from 'zustand';

interface User {
  session?: {
    access_token?: string;
  };
  access_token?: string;
}

interface HttpRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  headers?: { [key: string]: string };
  params?: { [key: string]: string };
  data?: any;
}

const useAuthStore = create<any>(
  persist(
    (set) => ({
      user: null,
      setUser: (user: User) => set({ user }),
      clearUser: () => set({ user: null }),
    }),
    {
      name: 'auth-token',
      getStorage: () => localStorage,
    },
  ),
);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  transformRequest: [
    (data, headers) => JSON.stringify(data),
  ],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config:any) => {
    const user = useAuthStore.getState()?.user as User;
    let accessToken = user?.session?.access_token || user?.access_token || null;
    if(!user){
      const localstorage = JSON.parse(localStorage.getItem("auth-token")!);   
      console.log("localstorage: ", localstorage); 
      accessToken = localstorage.state.user.access_token;
    }

    if (accessToken) {
      if (config.headers) {
        config.headers['access-token'] = `Bearer ${accessToken}`;
        config.headers['refresh-token'] = accessToken;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response: { status, config } } = error;
    if (status === 401 && config.headers.Authorization) {
      // Handle unauthorized errors
    } else {
      throw new Error(error.response.data.detail);
    }
  },
);

const httpRequest = async (options: HttpRequestOptions): Promise<AxiosResponse | any> => {
  if (Capacitor.isNativePlatform()) {
    const { method, url, headers, params, data } = options;
    const response = await CapacitorHttp.request({
      method,
      url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      params,
      data,
    });
    return {
      status: response.status,
      data: response.data,
      headers: response.headers,
      url: response.url,
    };
  } else {
    const response = await axiosInstance({
      method: options.method,
      url: options.url,
      headers: options.headers,
      data: options.data,
      params: options.params,
    });
    return response;
  }
};

export default httpRequest;
