import React from 'react';
import Lottie from 'lottie-react';

interface LottieAnimationProps {
  animationData: object;
  width?: string;
  height?: string;
  loop?: boolean;
  autoplay?: boolean;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  animationData,
  width = '100%',
  height = '100%',
  loop = true,
  autoplay = true,
}) => {
  return (
    <div style={{ width, height }}>
      <Lottie
        animationData={animationData}
        loop={loop}
        autoplay={autoplay}
className='w-full h-full'      />
    </div>
  );
};

export default LottieAnimation;
