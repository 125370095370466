import React, { useState } from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import { useNavigate } from "react-router";
import arrowRight from "../../../assets/imgs/arrow-right.svg";
import delRed from "../../../assets/imgs/del_red_icon.svg";
import card_icon from "../../../assets/imgs/visa_icon.svg";
import add_card from "../../../assets/imgs/add-card.svg";
import add_icon from "../../../assets/imgs/add-icon.svg";

interface PaymentMethod {
  icon?: string;
  text?: string;
  label: string;
  ExDate: string;
  action?: () => void;
  arrow?: boolean;
  lastItem?: boolean;
  customStyle?: string;
}

const PaymentMethodItem: React.FC<PaymentMethod> = ({
  icon,
  text,
  action,
  label,
  ExDate,
  arrow = true,
  lastItem,
  customStyle = "py-5",
}) => (
  <div
    className={customStyle}
    style={{
      borderBottom: !lastItem ? `1px solid ${Colors.backgroundSecundary}` : "",
    }}
    onClick={action}
  >
    <div className="flex items-center h-10">
      <img src={icon} alt="" className="mr-4" />
      <div className="">
        <Paragraph
          text={label}
          props={{
            fontSize: "15px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
          }}
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
        <Paragraph
          text={ExDate}
          props={{
            fontSize: "12px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            color: Colors.textLigth,
          }}
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
      </div>

      <div className="ml-auto">
        {arrow ? (
          <img src={arrowRight} alt="" className="mr-2" />
        ) : (
          <img src={delRed} alt="" className="mr-2" />
        )}
      </div>
    </div>
  </div>
);

const PaymentMethod = () => {
  const [toggleEdit, setToggleEdit] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <Paragraph
          text="Payment methods "
          props={{
            fontSize: "18px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            lineHeight: "14px",
            marginBottom: "16px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div onClick={() => setToggleEdit(!toggleEdit)}>
          <Paragraph
            text={!toggleEdit ? "Edit" : "Done"}
            props={{
              fontSize: "18px",
              fontWeight: 600,
              letterSpacing: "-0.5px",
              lineHeight: "14px",
              marginBottom: "16px",
              cursor: "pointer",
            }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
        </div>
      </div>

      <div
        className="max-w-sm mx-auto text-white rounded-[18px] px-[14px] py-4"
        style={{ backgroundColor: Colors.backgroundSecundary }}
      >
        <PaymentMethodItem
          icon={card_icon}
          label="•••• 2934"
          ExDate="Expires 02/2026"
          arrow={toggleEdit ? false : true}
          customStyle="pb-5"
        />
        <PaymentMethodItem
          icon={card_icon}
          label="•••• 1486"
          ExDate="Expires 08/2029"
          lastItem={!toggleEdit ? true : false}
          arrow={toggleEdit ? false : true}
          customStyle={!toggleEdit ? "pt-5" : "py-5"}
        />

        {toggleEdit && (
          <div
            className="flex items-center h-10 mt-5 cursor-pointer"
            onClick={() => navigate("/add-card")}
          >
            <img src={add_card} alt="" className="mr-4" />
            <div className="">
              <Paragraph
                text={"Add a card"}
                props={{
                  fontSize: "15px",
                  fontWeight: 600,
                  letterSpacing: "-0.5px",
                }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
            </div>

            <div className="ml-auto">
              <img src={add_icon} alt="" className="mr-2" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethod;
