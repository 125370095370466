import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const convertDateFormat = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return `${day}-${month}-${year}`;
};

export const parseDate = (dateString: string) => {
  const [day, month, year] = dateString.split(/[-/]/);
  return new Date(`${year}-${month}-${day}`);
};

export function snakeToTitleCase(snakeStr: string) {
  return snakeStr
    .split("_")
    .map(
      (word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(" ");
}

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const abbreviateName = (fullName: string): string => {
  const [firstName, lastName] = fullName.split(" ");
  if (!firstName || !lastName) return fullName;
  return `${firstName.charAt(0).toUpperCase()}. ${lastName}`;
};

export const getInitials = (fullName?: string | undefined | null) => {
  if (!fullName) {
    return "";
  }

  const nameParts = fullName.trim().split(" ");

  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  } else if (nameParts.length >= 2) {
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[1].charAt(0).toUpperCase()
    );
  }
};

export const validateEmail = (email: string) => {
   
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};


export   const convertInchesToFeet = (inches: number): string => {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  const formattedInches =
    remainingInches === Math.floor(remainingInches)
      ? remainingInches.toString()
      : remainingInches.toFixed(1);
  return `${feet}'${formattedInches}`;
};