import { useCallback, useEffect, useRef, useState, ReactNode } from "react";
import arrow from "../../../assets/imgs/arrow.svg";

import { Colors } from "../Colors/Colors";
import { Paragraph } from "../Paragraph";

interface ExpandableCardProps {
  header: ReactNode;
  children: ReactNode;
  }

export function ExpandableCard({ header, children }: ExpandableCardProps) {
  const [expanded, setExpanded] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const calculateHeight = useCallback(() => {
    if (headerRef.current && contentRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      const contentHeight = expanded ? contentRef.current.scrollHeight : 0;
      setHeaderHeight(headerHeight);
      setContentHeight(contentHeight);
    }
  }, [expanded]);

  useEffect(() => {
    calculateHeight();
  }, [calculateHeight]);

  useEffect(() => {
    calculateHeight();
  }, [expanded, calculateHeight]);

  return (
    <div
      className="inline-block max-w-sm mx-auto rounded-xl overflow-hidden mb-4 transition-all duration-500 ease-in-out p-4 relative"
      style={{
        background: Colors.backgroundSecundary,
      }}
    >
      <div onClick={toggleExpanded} ref={headerRef}>
        {header}
      </div>

      <div
        ref={contentRef}
        className="transition-all duration-300 ease-in-out"
        style={{
          height: expanded ? contentHeight : "0px",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
      <div onClick={toggleExpanded}>
        <img
          src={arrow}
          alt=""
          className={`absolute top-3 right-3 ${expanded ? "rotate-[180deg]" : "rotate-[0deg]"} transition-all duration-300 ease-in-out`}
        />
      </div>
    </div>
  );
}
