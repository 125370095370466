import HttpRequestChat from "../../Services/HttpRequestChat";

export const createFeedBack = async (
  email: string,
  accuracy: number,
  responseTime: number,
  easeOfUse: number,
  helpfulness: number,
  message: string,
) => {
  try {
    const response = await HttpRequestChat({
      method: "POST",
      url: "/v1/feedbacks",
      data: {
        email: email,
        accuracy: accuracy,
        response_time: responseTime,
        ease_of_use: easeOfUse,
        helpfulness: helpfulness,
        message: message,
      },
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const markFeedBack = async (
  detail_chat_id: string | null,
  uuid: string | null,
  status: string | null,
  feedback_text: string | null,
) => {
  try {
    const response = await HttpRequestChat({
      method: "PUT",
      url: `/v1/detail_chats/${detail_chat_id}/like_and_feedback`,
      data: {
        uuid: uuid,
        status: status,
        feedback_text: feedback_text ? feedback_text : null,
      },
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};
