import React, { useState } from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import phone_icon from "../../../assets/imgs/phone-icon.svg";
import email_icon from "../../../assets/imgs/emailIcon.svg";
import name_icon from "../../../assets/imgs/nameIcon.svg";
import cake_icon from "../../../assets/imgs/cakeIcon.svg";

interface UserProfileCardProps {
  phoneNumber: string;
  email: string;
  name: string;
  birthday: string;
}

const UserProfileCard: React.FC<UserProfileCardProps> = ({
  phoneNumber,
  email,
  name,
  birthday,
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const renderMaskedText = (
    show: boolean,
    text: string,
    maskFn: (text: string) => string,
  ) => (show ? text : maskFn(text));

  const renderInfoRow = (
    label: string,
    text: string,
    show: boolean | null,
    toggleShow?: () => void,
    maskFn?: (text: string) => string,
    lastChild = false,
  ) => (
    <div
      className="flex items-center py-5"
      style={{
        borderBottom: lastChild
          ? "none"
          : `1px solid ${Colors.backgroundSecundary}`,
      }}
    >
      {label === "Phone Number" && (
        <img src={phone_icon} alt="" className=" mr-2" />
      )}
      {label === "Email" && (
        <img src={email_icon} alt="" className=" mr-3" />
      )}
      {label === "Name" && (
        <img src={name_icon} alt="" className=" mr-3" />
      )}
      {label === "Birthday" && (
        <img src={cake_icon} alt="" className=" mr-3" />
      )}
      <Paragraph
        text={label}
        props={{
          fontSize: "15px",
          fontWeight: 600,
          letterSpacing: "-0.5px",
        }}
        ParagraphType="Paragraph22"
        color="textPrimary"
      />
      <div className="ml-auto text-gray-400" onClick={toggleShow}>
        <Paragraph
          text={
            show !== null && maskFn
              ? renderMaskedText(show, text, maskFn)
              : text
          }
          props={{
            fontSize: "15px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            color: Colors.textLight,
          }}
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
      </div>
    </div>
  );

  return (
    <div
      className="max-w-sm mx-auto text-white rounded-[18px] px-[14px] py-1"
      style={{ backgroundColor: Colors.backgroundSecundary }}
    >
      {renderInfoRow(
        "Phone Number",
        phoneNumber,
        showPhoneNumber,
        () => setShowPhoneNumber(!showPhoneNumber),
      )}
      {renderInfoRow(
        "Email",
        email,
        showEmail,
        () => setShowEmail(!showEmail),
      )}
      {renderInfoRow("Name", name, null)}
      {renderInfoRow("Birthday", birthday, null, undefined, undefined, true)}
    </div>
  );
};

export default UserProfileCard;
