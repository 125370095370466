import { useState, useEffect } from "react";
import { capitalizeFirstLetter } from "../lib/utils";
import { useSupabaseAuth } from "../Context/AuthContext/AuthContext";

const useUserProfile = () => {
  const { useAuthStore, getUserProfile } = useSupabaseAuth();
  const [firstName, setFirstName] = useState("");
  const [profile, setProfile] = useState(null);
  const [fullName, setFullName] = useState("");

  const fetchProfile = async (userStorage: any) => {
    const user_id = userStorage?.user?.id ? userStorage?.user?.id : userStorage?.user?.user?.id;
    
    const response = await getUserProfile(user_id);
    
    if (response) {
      try {
        const splitFirstName = response?.user_name.split(" ")[0];
        setFullName(response?.user_name);
        setFirstName(capitalizeFirstLetter(splitFirstName));
      } catch (error) {
        console.error("Error getting user profile", error);
      }
    }
  };

  useEffect(() => {
    const userStorage = useAuthStore.getState()?.user;
    fetchProfile(userStorage);
  }, [useAuthStore.getState()?.user]);

  // useEffect(() => {
  //   const userStorage = useAuthStore.getState()?.user;
  //   const profileStorage = useAuthStore.getState()?.profile;

  //   if (profileStorage) {
  //     try {
  //       if(profileStorage?.user_name){
  //         const splitFirstName = profileStorage?.user_name.split(" ")[0];
  //         setFirstName(capitalizeFirstLetter(splitFirstName));  
  //       }
  //       else {
  //         const splitFirstName = userStorage?.user_metadata?.full_name ? 
  //         userStorage?.user_metadata?.full_name.split(" ")[0] :
  //         userStorage?.user?.user_metadata?.full_name ? 
  //         userStorage?.user?.user_metadata?.full_name .split(" ")[0] : null
  //         if(splitFirstName){
  //           setFirstName(capitalizeFirstLetter(splitFirstName));  
  //         }

  //       }
  //       setProfile(profileStorage);
  //       return;
  //     } catch (error) {
  //       console.error("Error getting user profile", error);
  //     }
  //   }

  //   if (userStorage) {
  //     if (userStorage?.app_metadata?.provider === "google") {
  //       try {
  //         const splitFirstName =
  //           userStorage?.user_metadata?.full_name.split(" ")[0];
  //         setFirstName(capitalizeFirstLetter(splitFirstName));
  //         return;
  //       } catch (error) {
  //         console.error("Error getting user profile", error);
  //       }
  //       return;
  //     } else {
  //       if (!useAuthStore.getState()?.profile) {
  //         const fetchProfile = async () => {
  //           const response = await getUserProfile(userStorage?.user?.id);
  //           if (response) {
  //             try {
  //               const splitFirstName = response?.user_name.split(" ")[0];
  //               setFirstName(capitalizeFirstLetter(splitFirstName));
  //             } catch (error) {
  //               console.error("Error getting user profile", error);
  //             }
  //           }
  //         };

  //         fetchProfile();
  //       }
  //     }
  //   }
  // }, []);

  return { firstName, profile, fullName };
};

export default useUserProfile;
