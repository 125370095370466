import HttpRequestPlayerProfile from "../../Services/HttpRequestPlayerProfile";

export const getAllPlayerProfileCache = async () => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "GET",
      url: `/v1/players-profile`,
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const playerProfileCountViews = async (playerId: string) => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "POST",
      url: `/v1/players-profile/${playerId}/count-views`,
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};
