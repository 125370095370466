//components
import { Paragraph } from "../Commons/Paragraph";

//color palette
import { Colors } from "../Commons/Colors/Colors";
import SkeletonLoader from "../Commons/LoadingSkeleton/LoadingSkeleton";

export type SuggestionCarrouselProps = {
  items: Array<{
    id: number;
    title: string;
  }>;
  onSetQuestion: (question: string) => void;
  isAnimation?: boolean;
};

const SuggestionCarrousel: React.FC<SuggestionCarrouselProps> = ({
  items,
  onSetQuestion,
  isAnimation,
}) => {
  const handleSetQuestion = (question: any) => {
    onSetQuestion(question);
  };

  return (
    <div
      id="carrousel-section"
      className="flex overflow-auto hide-scrollbar -mx-5 sm:-mx-0 px-5 sm:px-0 gap-4"
      style={{
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      {!isAnimation
        ? items.map((item, index) => (
            <div
              key={index}
              className="flex flex-row rounded-[20px] min-w-[262px]"
              style={{
                background: Colors.backgroundSecundary,
                padding: "12px 14px",
                // whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              <div
                className="w-full"
                onClick={() => handleSetQuestion(item.title)}
              >
                <Paragraph
                  text={`Suggestion Topic #${index + 1}`}
                  ParagraphType="PragraghSemiBold400"
                  color="textPrimary"
                  className="!text-xs md:text-sm xl:text-[16px] !font-bold"
                />

                <Paragraph
                  text={item.title}
                  className="!text-[11px] md:text-[14px] xl:text-[13px] leading-[145%] text-[#ABABAB] mt-1"
                  ParagraphType="PragraghSemiBold400"
                  color="textLigth"
                />
              </div>
            </div>
          ))
        : items.map((item, index) => (
            <div
              key={index}
              className="flex flex-row items-center rounded-[20px] min-w-[262px] min-h-[75px]"
              style={{
                background: Colors.backgroundSecundary,
                padding: "12px 14px",
                // whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              <div className="flex gap-3 w-full">
                <SkeletonLoader
                  isLoading={isAnimation}
                  className="w-9 h-9 rounded-[6.84px]"
                >
                    <div></div>
                </SkeletonLoader>
                <div>
                  <SkeletonLoader
                    isLoading={isAnimation}
                    className="w-[174px] h-[10px] rounded-[2.28px]"
                  >
                    <div></div>
                  </SkeletonLoader>
                  <SkeletonLoader
                    isLoading={isAnimation}
                    className="w-[60%] h-[10px] rounded-[2.28px"
                  >
                    <div></div>
                  </SkeletonLoader>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default SuggestionCarrousel;
