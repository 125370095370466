const BrandLogo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 312 312"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="156" cy="156" r="156" fill="white" fillOpacity="0.1" />
      <path
        d="M261.317 219.802C254.831 223.727 237.522 219.15 228.398 215.843C222.554 213.019 212.471 207.612 207.375 203.305C204.668 201.017 203.03 197.481 202.69 193.953C202.107 187.904 206.483 182.077 226.384 187.409C250.502 194.841 260.041 206.066 262.762 213.139C263.675 215.514 263.494 218.485 261.317 219.802Z"
        fill="white"
      />
      <path
        d="M204.232 199.087C204.232 202.452 215.283 209.067 223.656 213.452C225.725 214.535 228.567 212.845 229.476 210.694C231.594 205.684 236.343 199.871 240.008 195.984C241.157 194.766 241.041 192.992 239.522 192.286C235.922 190.613 229.783 188.497 226.384 187.409C200.607 180.503 200.876 192.317 204.232 199.087Z"
        fill="#0048FF"
      />
      <path
        d="M53.6499 171.589C52.9655 179.14 64.6669 192.691 71.6384 199.441C76.7385 203.456 86.0189 210.148 92.1241 212.84C95.367 214.27 99.2631 214.192 102.583 212.951C108.276 210.824 111.594 204.336 98.0781 188.786C80.8258 170.368 66.5554 166.715 59.0048 167.37C56.4701 167.59 53.8796 169.055 53.6499 171.589Z"
        fill="white"
      />
      <path
        d="M97.2925 213.816C94.2686 215.291 83.4785 208.258 75.8668 202.655C73.9861 201.27 74.2598 197.975 75.7944 196.215C79.3685 192.115 82.512 185.299 84.3987 180.301C84.9899 178.734 86.6353 178.061 87.9352 179.116C91.0176 181.619 95.6097 186.209 98.0781 188.786C115.585 208.928 104.849 213.865 97.2925 213.816Z"
        fill="#0048FF"
      />
      <path
        d="M229.576 111.401L233.642 110.775C240.205 109.762 245.051 104.105 245.051 97.4551V84.4619C245.051 77.8096 240.205 72.1531 233.642 71.1425L229.576 70.5156V111.404V111.401Z"
        fill="#C4CCD6"
      />
      <path
        d="M82.4744 111.399L78.4083 110.773C71.8452 109.76 67 104.103 67 97.4532V84.46C67 77.8077 71.8452 72.1512 78.4083 71.1405L82.4744 70.5137V111.402V111.399Z"
        fill="#D3D9E2"
      />
      <path
        d="M157.153 66.5212C172.251 66.5212 184.491 57.6741 184.491 46.7606C184.491 35.8471 172.251 27 157.153 27C142.054 27 129.814 35.8471 129.814 46.7606C129.814 57.6741 142.054 66.5212 157.153 66.5212Z"
        fill="#D3D9E2"
      />
      <path
        d="M80.875 110.848C80.875 120.174 85.9714 128.754 94.161 133.191C104.571 138.83 123.072 144.056 155.895 144.056C188.717 144.056 207.218 138.83 217.628 133.191C225.818 128.754 230.914 120.174 230.914 110.848V69.9599C230.914 60.634 225.818 52.0543 217.628 47.6173C207.218 41.9778 188.717 36.752 155.895 36.752C123.072 36.752 104.571 41.9778 94.161 47.6173C85.9714 52.0543 80.875 60.634 80.875 69.9599V110.848Z"
        fill="#E4E8EF"
      />
      <path
        d="M194.55 58.5299C193.49 52.0227 188.379 43.2404 183.904 38.2832C200.413 40.1211 211.598 44.3534 217.629 47.6177C225.818 52.0546 230.917 60.6344 230.917 69.9603V110.846C230.917 120.172 225.818 128.752 217.629 133.187C207.219 138.826 188.717 144.052 155.895 144.052C123.073 144.052 104.574 138.824 94.1616 133.187C90.4 131.15 87.294 128.24 85.0332 124.784C101.067 130.632 118.077 133.799 135.141 134.101C138.386 134.159 141.666 134.101 144.93 133.875L194.552 58.5278L194.55 58.5299Z"
        fill="#D3D9E2"
      />
      <path
        d="M220.581 194.518C220.581 205.475 219.565 215.507 217.677 224.57C217.487 225.494 217.285 226.408 217.074 227.312C208.453 264.409 184.88 284.496 157.155 284.496C129.429 284.496 105.856 264.409 97.2347 227.312C97.0218 226.408 96.8217 225.494 96.6322 224.57C94.7461 215.507 93.7285 205.473 93.7285 194.518C93.7285 170.143 119.181 154.523 157.155 154.523C195.128 154.523 220.581 170.143 220.581 194.518Z"
        fill="#E4E8EF"
      />
      <path
        d="M157.153 134.16C125.564 134.16 109.346 128.968 101.306 124.612C95.8815 121.674 92.5137 116.007 92.5137 109.821V79.5961C92.5137 73.4108 95.8836 67.7436 101.306 64.8055C109.346 60.4496 125.564 55.2578 157.153 55.2578C188.743 55.2578 204.96 60.4496 213.001 64.8055C218.425 67.7436 221.793 73.4108 221.793 79.5961V109.821C221.793 116.007 218.423 121.674 213.001 124.612C204.96 128.968 188.743 134.16 157.153 134.16Z"
        fill="#0048FF"
      />
      <path
        d="M141.569 90.2428C141.569 96.1829 136.762 93.2256 130.832 93.2256C124.901 93.2256 120.094 96.1808 120.094 90.2428C120.094 84.3048 124.901 79.4883 130.832 79.4883C136.762 79.4883 141.569 84.3026 141.569 90.2428Z"
        fill="white"
      />
      <path
        d="M166.121 109.747C166.121 112.228 165.116 114.474 163.494 116.098C161.87 117.725 159.628 118.729 157.152 118.729C152.199 118.729 148.182 114.706 148.182 109.744C148.182 109.391 148.324 109.069 148.556 108.836C148.788 108.604 149.11 108.461 149.463 108.461H164.84C165.546 108.461 166.121 109.037 166.121 109.744V109.747Z"
        fill="white"
      />
      <path
        d="M220.579 194.519C220.579 205.476 219.563 215.507 217.675 224.571C217.486 225.496 217.283 226.409 217.07 227.313C208.449 264.41 184.876 284.497 157.151 284.497C141.072 284.497 126.391 277.742 115.213 264.832C123.236 267.774 132.356 268.685 140.955 267.361C156.629 264.943 170.328 255.847 179.033 244.27C187.737 232.692 191.795 218.829 192.825 205.03C193.794 192.037 192.004 178.43 184.155 167.338C180.749 162.524 176.012 158.3 170.53 155.215C200.983 158.486 220.579 173.133 220.579 194.516V194.519Z"
        fill="#D3D9E2"
      />
      <path
        d="M221.793 79.5948V109.82C221.793 116.005 218.425 121.672 213.001 124.611C204.958 128.966 188.743 134.158 157.154 134.158C152.792 134.158 148.721 134.058 144.928 133.877C180.671 129.595 199.044 111.581 199.044 86.3643C199.044 79.2089 197.441 70.0066 194.55 58.5293C203.204 60.3544 209.078 62.6784 213.001 64.8042C218.425 67.7423 221.793 73.4095 221.793 79.5948Z"
        fill="#0930C6"
      />
      <path
        d="M194.21 90.2428C194.21 94.8077 191.37 94.119 187.361 93.554C186.157 93.3855 184.843 93.2278 183.472 93.2278C177.543 93.2278 172.734 96.1829 172.734 90.2428C172.734 84.3026 177.541 79.4883 183.472 79.4883C184.911 79.4883 186.286 79.774 187.54 80.2878C191.453 81.8912 194.21 85.744 194.21 90.2428Z"
        fill="white"
      />
    </svg>
  );
};

export default BrandLogo;
