import { useEffect, useState } from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import { Paragraph } from "../Commons/Paragraph";
import { Button } from "../ui/button";
import PlayerScrollView from "./PlayerScrollView/PlayerScrollView";
import { PlayerProfileById } from "../../Controllers/PlayerProfileById/index";
import { useParams } from "react-router-dom";
import Game_manager_icon from "../../assets/imgs/game_manager.svg";
import Goal_line_back_icon from "../../assets/imgs/goal-line-back.svg";
import Receiving_back_icon from "../../assets/imgs/receiving_back.svg";
import Scrambler_icon from "../../assets/imgs/Scrambler.svg";
import Target_hog_icon from "../../assets/imgs/target_hog.svg";
import Work_horse_icon from "../../assets/imgs/work_horse.svg";
import Gunslinger_icon from "../../assets/imgs/gunslinger.svg";
import EyeIcon from "../../assets/imgs//eye-white-icon.svg";
import Red_zone_threat_icon from "../../assets/imgs/red-zone-threat.svg";
import Yac_monster_icon from "../../assets/imgs/target_hog.svg";
import PlayerScoreCards from "./PlayerScoreCards/PlayerScoreCards";
import PlayerProfileDetails from "./PlayerProfileDetails";
import { AnimatePresence, motion } from "framer-motion";

import chatbotAnimation from "../../assets/animations/Chatbot.json";
import LottieAnimation from "../Commons/LottieAnimation/LottieAnimation";
import PlayersTabs from "../Commons/PlayerTabs/PlayerTabs";
import PlayerScores from "./PlayerScores/PlayerScores";
import ResearchOverview from "../Research/ResearchOverview/ResearchOverview";
import ResearchStats from "../Research/ResearchStats/ResearchStats";
import ChatbotSidebar from "../Chatbot/ChatbotSidebar/ChatbotSidebar";
import StockWatchSidebar from "../StockWatchSidebar/StockWatchSidebar";
import { playerDummies } from "../Rankings/data";
import { convertInchesToFeet } from "../../lib/utils";
import eye_icon from "../../assets/imgs/eye-white-icon.svg";

const PlayerProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [playerId] = useState<string | undefined>(id);
  const [player, setPlayer] = useState<any>();
  const [selected, setSelected] = useState<number | null>(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(
    null,
  );
  const [useBadge, setUseBadge] = useState<boolean>(false);
  const [performanceScores, setPerformanceScores] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [playersCard, setPlayersCard] = useState(playerDummies);
  const [playerDetails, setPlayerDetails] = useState<any>([]);  

  const handleTabChange = (index: number) => {
    console.log(`Selected Tab: ${index}`);
    setSelectedTab(index);
  };
  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await PlayerProfileById(playerId as string);
        setPlayer(response?.data?.data);
      } catch (error) {
        console.error("Error fetching player data:", error);
      }
    };

    if (playerId) {
      fetchPlayerData();
    }
  }, [playerId]);

  const getBadgeIcon = (badgeType: string) => {
    switch (badgeType) {
      case "Game Manager":
        return Game_manager_icon;
      case "Goal Line Back":
        return Goal_line_back_icon;
      case "Receiving Back":
        return Receiving_back_icon;
      case "Scrambler":
        return Scrambler_icon;
      case "Target Hog":
        return Target_hog_icon;
      case "Work Horse":
        return Work_horse_icon;
      case "Gunslinger":
        return Gunslinger_icon;
      case "Red Zone Threat":
        return Red_zone_threat_icon;
      case "Yac Monster":
        return Yac_monster_icon;
      case "Rusher":
        return Scrambler_icon;
      case "Bell Cow":
        return Work_horse_icon;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (player) {
      const scores = [
        {
          score: player?.playerPerformanceScores?.playerValues?.playerTradeValue
            ? Number(
                player.playerPerformanceScores.playerValues.playerTradeValue,
              ).toFixed(1)
            : "--",
          label: "Trade Value",
        },
        {
          score: player?.playerPerformanceScores?.playerValues?.playerADP
            ? Number(
                player.playerPerformanceScores.playerValues.playerADP,
              ).toFixed(1)
            : "--",
          label: "ADP",
        },
        {
          score: player?.playerPerformanceScores?.playerValues?.playerSeasonFP
            ? Number(
                player.playerPerformanceScores.playerValues.playerSeasonFP,
              ).toFixed(1)
            : "--",
          label: "2023 Fantasy Points",
        },
      ];

      const badgeIcon = getBadgeIcon(
        player?.playerPerformanceScores?.playerValues?.playerBadgeType,
      );
      const badgeType =
        player?.playerPerformanceScores?.playerValues?.playerBadgeType;

      if (badgeType && badgeType !== "Null") {
        scores.splice(1, 0, {
          score: badgeIcon,
          label: badgeType,
        });
        setUseBadge(true);
      } else {
        setUseBadge(false);
      }

      setPerformanceScores(scores);
    }
  }, [player]);

  useEffect(() => {
    if (player) {
      const PlayerDetailsData = [
        {
          title: player.playerPositionRank,
          subTitle: "Pos Rank",
        },
        {
          title: player.playerOverallRank,
          subTitle: "Ovr. Rank",
        },
        {
          title: player.playerAge,
          subTitle: "Age",
        },
        {
          title: `${player.playerExperience}yr`,
          subTitle: "Experience",
        },
        {
          title: player.playerHeight,
          subTitle: "Height",
        },
        {
          title: player.playerWeight,
          subTitle: "Weight",
        },
        {
          title: player?.playerPerformanceScores?.playerValues?.playerSeasonFP,
          subTitle: "Avg. FP",
        },
        {
          title: player?.playerPerformanceScores?.playerValues?.playerTradeValue,
          subTitle: "Total FP",
        },

      ];
      setPlayerDetails(PlayerDetailsData);
    }
  }, [player]);

  const handleExpand = (index: number) => {
    if (expandedCardIndex === index) {
      setExpandedCardIndex(null);
    } else {
      setExpandedCardIndex(index);
    }
  };

  const handleCollapse = () => {
    setExpandedCardIndex(null);
  };

  console.log("player", player);

  const tabs = [
    { label: "Scores", content: <PlayerScores player={player} /> },
    {
      label: "Latest News",
      content: <ResearchOverview data={player} />,
    },
    {
      label: "Game Log",
      content: (
        <div className="-mx-5">
          <ResearchStats
            statsData={player?.statsSummary}
            weeklyStats={player?.weeklyStats}
            playerPosition={player?.playerPosition}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="flex">
      <div>
        <StockWatchSidebar dummies={playersCard as []} />
      </div>
      <Layout>
        <div className="-mt-5 pt-5 -mx-5 px-5 transition-all duration-500">
          <div className="flex items-center justify-between relative z-10">
            <div
              className="cursor-pointer rounded-full w-[58px] h-[58px] flex justify-center items-center"
              style={{
                background: Colors.backgroundSecundary,
              }}
              onClick={() => navigate(-1)}
            >
              <img src={ArrowBackIcon} alt="Back" />
            </div>
            <Paragraph
              text="Player Profile"
              ParagraphType="Paragraph22"
              color="textPrimary"
              className="text-[22px] md:text-2xl lg:text-[34.091px] font-bold leading-[100%]"
            />
            <div
              className="cursor-pointer flex justify-center items-center"
              onClick={() => navigate(`/player/${playerId}/chat/new`)}
            >
              <div className=" rounded-full w-[58px] h-[58px] sm:hidden">
                <LottieAnimation animationData={chatbotAnimation} />
              </div>
              <div
                className="rounded-full w-auto h-[58px] sm:flex items-center gap-2 px-4 hidden"
                style={{
                  background:
                    "linear-gradient(to top right, #602087 9.42%, #3133CF 51%, #2DA1F9 88.63%)",
                }}
              >
                <img src={eye_icon} className="lg:w-[40.813px] md:w-[38.813px] sm:w-8" alt=""/>
                <Paragraph
                  text={`Ask About ${player?.playerFirstName.split(" ")[0]}`}
                  className="text-sm md:text-lg lg:text-xl !font-medium"
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
              </div>
            </div>
          </div>
          <div className="relative">
            <div
              className={`flex justify-between mt-8 relative items-end ${expandedCardIndex !== null && "-z-10"}`}
            >
              <PlayerScrollView
                setScrollPosition={setScrollPosition}
                scrollPosition={scrollPosition}
                player={player}
              />
              <div
                className="absolute -top-10 opacity-10 w-full h-full sm:hidden"
                style={{
                  backgroundImage: `url(${player?.playerTeamImageURL})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div
              className="absolute top-0 hidden sm:block sm:-top-[110px] lg:-top-[140px] sm:-left-[200px] lg:min-h-[603px] md:min-h-[475px] opacity-10 w-full h-full"
            >
              <img
                src={player?.playerTeamImageURL}
                alt="player?.playerTeamImageURL"
                className="w-full h-[96.8%] object-cover"
              />
            </div>
          </div>
        </div>

        <div
          className="py-6 px-4 -mx-5 flex gap-4 overflow-x-auto no-scrollbar"
          style={{
            backgroundColor: Colors.backgroundGraySecondory,
          }}
        >
          <div className="flex sm:hidden">

          {performanceScores.length > 0
            ? performanceScores.map((score, index) => (
                <div className="flex-shrink-0 flex gap-4" key={index}>
                  <div
                    className={`text-center w-[135.2px] h-[101px] flex items-center justify-center flex-col p-[10px] rounded-xl cursor-pointer ${
                      0 === index ? `bg-[${Colors.backgroundSecundary}]` : ""
                    }`}
                  >
                    {index === 1 && useBadge ? (
                      score.score !== null ? (
                        <img
                          className="h-[41px]"
                          src={score.score}
                          alt={score.label}
                        />
                      ) : (
                        <Paragraph
                          text={"--"}
                          props={{
                            fontSize: "40px",
                            fontWeight: 600,
                            lineHeight: "100%",
                          }}
                          ParagraphType="Paragraph22"
                          color="textPrimary"
                        />
                      )
                    ) : (
                      <Paragraph
                        text={score.score}
                        props={{
                          fontSize: "40px",
                          fontWeight: 600,
                          lineHeight: "100%",
                        }}
                        ParagraphType="Paragraph22"
                        color="textPrimary"
                      />
                    )}
                    {selected === index && (
                      <div
                        className="h-[2px] w-full my-1"
                        style={{ backgroundColor: Colors.backgroundSecundary }}
                      ></div>
                    )}
                    <div className="flex items-center gap-1">
                      {selected === index && (
                        <img
                          className="h-[12px]"
                          src={EyeIcon}
                          alt={"EyeIcon"}
                        />
                      )}
                      <Paragraph
                        text={index == 0 ? "RotoBot Score" : score.label}
                        props={{
                          fontSize: "9.6px",
                          color: Colors.textLigth,
                          lineHeight: "100%",
                          marginTop: selected !== index ? "10px" : "",
                        }}
                        ParagraphType="Paragraph22"
                        color="textPrimary"
                      />
                    </div>
                  </div>
                  {index !== performanceScores.length - 1 && (
                    <div
                      className={`h-12 w-px bg-[${Colors.backgroundSecundary}] my-auto`}
                    ></div>
                  )}
                </div>
              ))
            : ["RotoBot Score", "Yards This Season", "TDs This Season"].map(
                (item, index) => {
                  return (
                    <div className="flex-shrink-0 flex gap-4" key={index}>
                      <div
                        className={`text-center w-[135.2px] h-[101px] flex items-center justify-center flex-col p-[10px] rounded-xl cursor-pointer ${
                          selected === index
                            ? `bg-[${Colors.backgroundSecundary}]`
                            : ""
                        }`}
                        onClick={() => setSelected(index)}
                      >
                        <Paragraph
                          text={"--"}
                          props={{
                            fontSize: "40px",
                            fontWeight: 600,
                            lineHeight: "100%",
                          }}
                          ParagraphType="Paragraph22"
                          color="textPrimary"
                        />
                        {selected === index && (
                          <div
                            className="h-[2px] w-full my-1"
                            style={{
                              backgroundColor: Colors.backgroundSecundary,
                            }}
                          ></div>
                        )}
                        <div className="flex items-center gap-1">
                          {selected === index && (
                            <img
                              className="h-[12px]"
                              src={EyeIcon}
                              alt={"EyeIcon"}
                            />
                          )}
                          <Paragraph
                            text={item}
                            props={{
                              fontSize: "9.6px",
                              color: Colors.textLigth,
                              lineHeight: "100%",
                              marginTop: selected !== index ? "10px" : "",
                            }}
                            ParagraphType="Paragraph22"
                            color="textPrimary"
                          />
                        </div>
                      </div>
                      {index !== performanceScores.length - 1 && (
                        <div
                          className={`h-12 w-px bg-[${Colors.backgroundSecundary}] my-auto`}
                        ></div>
                      )}
                    </div>
                  );
                },
              )}
          </div>
          <div className="sm:flex hidden">
          {playerDetails.length > 0 &&
            playerDetails.map((item: any, index: number) => {
              return (
                <div className="flex-shrink-0 flex gap-4" key={index}>

                  <div
                    className={`text-center w-[115.2px] h-[101px] flex items-center justify-center flex-col p-[10px] rounded-xl cursor-pointer ${
                      0 === index ? `bg-[${Colors.backgroundSecundary}]` : ""
                    }`}
                  >
                    <Paragraph
                      text={
                        item.subTitle === "Height"
                          ? convertInchesToFeet(item.title)
                          : item.subTitle === "Height"
                            ? item.title.toString().replace("lbs", "")
                            : item.subTitle === "Avg. FP"
                            ?  Number(item.title).toFixed(1)
                            : item.title
                      }
                      props={{
                        fontSize: "34px",
                        fontWeight: 600,
                        lineHeight: "100%",
                      }}
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                    />
                    <div className="flex items-center gap-1">
                      <Paragraph
                        text={item.subTitle}
                        props={{
                          fontSize: "9.6px",
                          color: Colors.textLigth,
                          lineHeight: "100%",
                          marginTop: "10px",
                        }}
                        ParagraphType="Paragraph22"
                        color="textPrimary"
                      />
                    </div>
                  </div>
                  <div
                    className={`h-12 w-px bg-[${Colors.backgroundSecundary}] my-auto`}
                  ></div>
                </div>
              );
            })}
            </div>
        </div>
        <div className="mt-3">
          <PlayersTabs tabs={tabs} onTabChange={handleTabChange} />
        </div>

        <div className="pb-5" />
        <AnimatePresence>
          {expandedCardIndex !== null && (
            <motion.div
              className="w-full"
              initial={{
                opacity: 0,
              }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              <PlayerProfileDetails
                item={
                  player?.playerPerformanceScores?.performanceCards[
                    expandedCardIndex
                  ]
                }
                onClose={handleCollapse}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Layout>
    </div>
  );
};

export default PlayerProfile;
