import React, { useState, ChangeEvent } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { Colors } from "../Commons/Colors/Colors";

import { Layout } from "../Layouts/Layout";

import { supabase } from "../../supabase/supabase.config";

import { VerififcationOtp } from "../VerififcationOtp";
import VerifyPhoneNumberWidget from "./VerifyPhoneNumberWidget";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { Paragraph } from "../Commons/Paragraph";

const STEP = ["INPUT_PHONE", "ENTER_CODE"];
export const VerificationPhoneNumber: React.FC = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isValid, setIsValid] = useState(true);
  const [step, setStep] = useState<string>(STEP[0]);
  const { sendSMS, verifyOTPCode, resendOtpCode } = useSupabaseAuth();
  const [errors, setErrors] = useState<string | null>(null);
  const [errorsPhoneNumber, setErrorsPhoneNumber] = useState<string | null>(
    null,
  );
  const numInputs = 6;

  const handlePhoneNumberChange = (value: string) => {
    console.log("value", value);
    if (value !== null) {
      setPhoneNumber(value);
      setIsValid(isValidPhoneNumber(value));
    }
  };

  const handleSendSMS = async () => {
    const status = await sendSMS(phoneNumber);

    if (status) {
      console.log("Your SMS code has been sent");
      setStep(STEP[1]);
      setErrors(null);
    } else {
      setErrorsPhoneNumber("Sorry your SMS could not be sent.");
    }
  };

  const isValidToken = (token: string) => {
    if (!token) return false;

    if (token.length < numInputs) return false;
    return true;
  };

  const handleVerifyOtp = async (phone: string, token: string) => {
    if (!isValidToken(token)) {
      setErrors("Please input verification code.");
      return;
    }

    const status = await verifyOTPCode(phoneNumber, token);
    if (status) {
      setErrors(null);
      navigate("/user-details");
    } else {
      setErrors("Incorrect verification code, please try again.");
    }
  };

  const handleResendCode = async () => {
    const status = await resendOtpCode(phoneNumber);

    if (status) {
      console.log("Your SMS code has been resent");
      setErrors(null);
    } else {
      setErrors("Sorry your SMS could not be resent.");
    }
  };

  const handleGoBack = () => {
    switch (step) {
      case STEP[0]:
        navigate("/welcome");
        break;
      default:
        setStep(STEP[0]);
        break;
    }
  };

  return (
    <Layout>
      <div className="flex flex-col h-full relative">
        <div className="flex items-center justify-between">
          <div
            className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
            style={{
              background: Colors.backgroundSecundary,
            }}
            onClick={handleGoBack}
          >
            <img src={ArrowBackIcon} alt="" />
          </div>
          <Paragraph
            text="Verification"
            props={{ fontSize: "22px", marginLeft: "-45px" }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <div></div>
        </div>
        {step === STEP[0] ? (
          <VerifyPhoneNumberWidget
            phoneNumber={phoneNumber}
            handlePhoneNumberChange={handlePhoneNumberChange}
            isValid={isValid}
            handleSendSMS={handleSendSMS}
          />
        ) : (
          <VerififcationOtp
            phone={phoneNumber}
            onVerifyOTPCode={handleVerifyOtp}
            onResendCode={handleResendCode}
            localError={errors}
            setLocalError={setErrors}
          />
        )}
      </div>
    </Layout>
  );
};

// export default Login;
