import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerComponentProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  customInput?: React.ReactElement,
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  selected,
  onChange,
  customInput,
}) => {
  const ExampleCustomInput = forwardRef<HTMLButtonElement, { value: any; onClick: any }>(
    ({ value, onClick }, ref) => (
      <button className="example-custom-input" onClick={onClick} ref={ref}>
        {value}
      </button>
    )
  );

  ExampleCustomInput.displayName = 'ExampleCustomInput';

  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      customInput={customInput || <ExampleCustomInput value={selected} onClick={onChange} />}
      popperProps={{
        strategy: 'fixed',
      }}
      popperPlacement="bottom-end"
      maxDate={new Date()} 
    />
  );
};

export default DatePickerComponent;
