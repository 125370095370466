import React, { useState } from "react";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";

export const SignInWithApple: React.FC = () => {
  const [userStatus, setUserStatus] = useState<
    "NEW" | "EXISTING" | "UNDETERMINED"
  >("UNDETERMINED");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const { signInWithApple } = useSupabaseAuth();

  const handleAppleSignIn = () => {
    console.log("Signing in with Apple...");
    const status = Math.random() > 0.5 ? "NEW" : "EXISTING";
    try {
      signInWithApple();
      setUserStatus(status);
    } catch (error) {
      console.log("  ***   " + error);
    }
  };

  const verifyPhoneNumber = () => {
    console.log("Verifying phone number...");
    setUserStatus("EXISTING");
  };

  return (
    <div className="flex flex-col items-center justify-center p-5 mt-12 h-4/5">
      {userStatus === "UNDETERMINED" && (
        <>
          <h2 className="mb-4">Sign In with Apple</h2>
          <button
            className="bg-black text-white font-bold py-2 px-4 rounded cursor-pointer hover:bg-gray-900 transition-colors mb-4"
            onClick={handleAppleSignIn}
          >
            Sign In with Apple
          </button>
        </>
      )}

      {userStatus === "NEW" && (
        <>
          <h2 className="mb-4">
            Welcome! Please verify your phone number and name.
          </h2>
          <input
            className="mb-4 p-2 border rounded text-lg w-60"
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            className="mb-4 p-2 border rounded text-lg w-60"
            type="text"
            placeholder="Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer transition-colors"
            onClick={verifyPhoneNumber}
          >
            Verify Phone Number
          </button>
        </>
      )}

      {userStatus === "EXISTING" && (
        <h2>Welcome back! Redirecting to home screen...</h2>
      )}
    </div>
  );
};
