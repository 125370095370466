import EmptyChatIcon from "../../../assets/imgs/emptyChatIcon.png";
import { Paragraph } from "../../Commons/Paragraph";
import BrandLogo from "../../Commons/BrandLogo/BrandLogo";
import ChatbotImg from "../../../assets/imgs/chatbot-without-bg.svg";

export const EmptyChat: React.FC = () => {
  return (
    <div
      id="chat-empty"
      className="flex h-full w-full items-center text-center justify-center "
    >
      <div className=" relative h-full flex justify-center items-center w-full">
        <img
          src={ChatbotImg}
          alt=""
          className="h-[80%] max-h-[370px] sm:max-h-full lg:min-w-[330.25px] md:min-w-[270px] sm:min-w-[260px] min-w-[260px] absolute z-10 top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] "
        />
        <div className="h-[40%] sm:h-[30vh] w-[40%] lg:w-[250.474px] md:w-[250.474px] sm:w-[220.474px] chatbot-bot-bg"></div>
      </div>
    </div>
  );
};
