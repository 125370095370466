import { colorProps } from "./types";

export const Colors: colorProps = {
  textPrimary: "white",
  default: "red",
  textLigth: "rgba(255, 255, 255, 0.70)",
  menuMobile: "#181A2078",
  backgroundSecundary: "#ffffff1a",
  backgroundTertiary: "#0048FF",
  borderLigth: "rgba(255, 255, 255, 0.20)",
  backgroundPrimary: "var(--Primary--Blie, #0048FF)",
  backgroundColorUser: "#7138CE",
  backgroundGrayPrimary: "#ffffff4d",
  backgroundGraySecondory: "#d9d9d914",
  backgroundGrayTertiary: "#FFFFFF0F",
  backgroundGrayBluish: "#0066FF1A",
  transparentBackground: "rgba(24, 26, 32, 0.50)",
  filterMenu: "blur(9px)",
  bgGreenPrimary: "#16C265",
  textRed: "#FF0000",
  bgCyan: "#00C7FE",
  bgNegativeRed: "#F11E84"
};
