import React from 'react';
import { MenuMobileProps } from "./type";

//style
import { CSSProperties } from "react";
import { Colors } from "../../Commons/Colors/Colors";

//components
import { Paragraph } from "../../Commons/Paragraph";

//icons
import CharPersonIcon from "../../../assets/imgs/player-analyzer.svg";
import DataBaseIcon from "../../../assets/imgs/team-analyzer.svg";
import PeopleComunityIcon from "../../../assets/imgs/sit-start-decider.svg";
import PersonQuestionIcon from "../../../assets/imgs/waiver-assistant.svg";
import VectorIcon from "../../../assets/imgs/league-selector.svg";
import TradeAnalyzer from "../../../assets/imgs/trade-analyzer.svg";
import CancelIcon from "../../../assets/imgs/cancel-icon.png";
import { motion, AnimatePresence } from "framer-motion";

export const LandingScreenMenu: React.FC<MenuMobileProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const menuContainerStyle: CSSProperties = {
    position: "fixed",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundColor: Colors.transparentBackground,
    backdropFilter: Colors.filterMenu,
    boxShadow: "-2px 0 5px rgba(0,0,0,0.5)",
    zIndex: 100,
  };

  const menuOverlayStyle: CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 19,
  };

  const closeButtonStyle: CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    background: Colors.backgroundSecundary,

  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              id="menu-wrapper"
              style={menuContainerStyle}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
            >
              <div 
          style={closeButtonStyle} 
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"

          onClick={() => setIsOpen(false)}
          aria-label="Close menu"
        >
          <img src={CancelIcon} alt="Close menu" />
        </div>
              <div className="h-full flex items-end pb-14 relative">
                <div
                  id="options-wrapper"
                  className="mx-[18px] relative z-[102] w-full"
                >
                  <ul className="space-y-3.5">
                    <li>
                      <a href="/home" className="flex items-center">
                        <div
                          className="mr-4 rounded-full p-4"
                          style={{ background: Colors.backgroundSecundary }}
                        >
                          <img src={VectorIcon} alt="" className="w-[30px]" />
                        </div>
                        <Paragraph
                          text="League Selector"
                          props={{ fontSize: "16px", fontWeight: 600 }}
                          ParagraphType="PragraghSemiBold400"
                          color="textPrimary"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/home" className="flex items-center">
                        <div
                          className="mr-4 rounded-full p-4"
                          style={{ background: Colors.backgroundSecundary }}
                        >
                          <img src={CharPersonIcon} alt="" className="w-[30px]" />
                        </div>
                        <Paragraph
                          text="Player Analyzer"
                          props={{ fontSize: "16px", fontWeight: 600 }}
                          ParagraphType="PragraghSemiBold400"
                          color="textPrimary"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/home" className="flex items-center">
                        <div
                          className="mr-4 rounded-full p-4"
                          style={{ background: Colors.backgroundSecundary }}
                        >
                          <img src={TradeAnalyzer} alt="" className="w-[30px]" />
                        </div>
                        <Paragraph
                          text="Trade Analyzer"
                          props={{ fontSize: "16px", fontWeight: 600 }}
                          ParagraphType="PragraghSemiBold400"
                          color="textPrimary"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/team-analyzer" className="flex items-center">
                        <div
                          className="mr-4 rounded-full p-4"
                          style={{ background: Colors.backgroundSecundary }}
                        >
                          <img src={DataBaseIcon} alt="" className="w-[30px]" />
                        </div>
                        <Paragraph
                          text="Team Analyzer"
                          props={{ fontSize: "16px", fontWeight: 600 }}
                          ParagraphType="PragraghSemiBold400"
                          color="textPrimary"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/home" className="flex items-center">
                        <div
                          className="mr-4 rounded-full p-4"
                          style={{ background: Colors.backgroundSecundary }}
                        >
                          <img src={PeopleComunityIcon} alt="" className="w-[30px]" />
                        </div>
                        <Paragraph
                          text="Sit/Start Decider"
                          props={{ fontSize: "16px", fontWeight: 600 }}
                          ParagraphType="PragraghSemiBold400"
                          color="textPrimary"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="/home" className="flex items-center">
                        <div
                          className="mr-3 rounded-full p-4"
                          style={{ background: Colors.backgroundSecundary }}
                        >
                          <img src={PersonQuestionIcon} alt="" className="w-[30px]" />
                        </div>
                        <Paragraph
                          text="Waiver Assistant"
                          props={{ fontSize: "16px", fontWeight: 600 }}
                          ParagraphType="PragraghSemiBold400"
                          color="textPrimary"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  role="button"
                  style={menuOverlayStyle}
                  onClick={() => setIsOpen(false)}
                  tabIndex={0}
                  aria-label="Close overlay"
                  className="h-screen w-full absolute top-0 left-0 z-[100]"
                ></div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};
