import HttpRequestPlayerProfile from "../../Services/HttpRequestPlayerProfile";

export const PlayerProfileById = async (playerId:string) => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "GET",
      url: `/v1/players-profile/${playerId}`,
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};
