import React from "react";
import { ExpandableCard } from "../../Commons/ExpandableCard/Expandablecard";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import Plus from "../../../assets/imgs/big-plus.svg";

const ResearchExpandCard = (props: any) => {
  const { title, subTitle, plus, description } = props;

  const getTitle = (title:string) => {
    switch (title) {
      case "seasonRecap":
        return "Season Recap";
      case "seasonOutlook":
        return "Season Outlook";
      case "weeklyRecap":
        return "Weekly Recap";
      case "weeklyOutlook":
        return "Weekly Outlook";
      default:
        return "";
    }
  };

  return (
    <ExpandableCard
      header={
        <div className="flex justify-between items-center">
          <div
            className={`w-[70%] transition-colors duration-300 ease-in-out relative z-20 cursor-pointer `}
          >
            <Paragraph
              text={getTitle(subTitle)}
              props={{
                fontSize: "12px",
                fontWeight: 500,
                color: Colors.textLigth,
              }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
            <Paragraph
              text={title}
              props={{ fontSize: "18px", fontWeight: 500 }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
          <div className="flex-1">
            {plus ? (
              <img src={Plus} alt="" className="w-9 h-9 m-auto" />
            ) : (
              <div className="w-9 h-1 bg-[#F11E84] rounded-sm m-auto"></div>
            )}
          </div>
        </div>
      }
    >
      <div className="mt-3">
        <Paragraph
          text={description}
          props={{
            fontSize: "12px",
            fontWeight: 500,
            color: Colors.textLigth,
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
      </div>
    </ExpandableCard>
  );
};

export default ResearchExpandCard;
