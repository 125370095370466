import React from "react";
import { Layout } from "../Layouts/Layout";
import SettingItem from "../Commons/SettingItem/SettingItem";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import premium_icon from "../../assets/imgs/premium.svg";
import snooze_membership from "../../assets/imgs/snooze-membership.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { useNavigate } from "react-router";

const Membership = () => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/subscription");
  };
  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
          style={{ background: Colors.backgroundSecundary }}
          onClick={navigateBack}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="Membership"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>
      <div className="mt-5">
        <Paragraph
          text="Membership"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            lineHeight: "14px",
            marginBottom: "16px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[14px] py-4"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          <SettingItem
            icon={premium_icon}
            label="Manage Membership"
            customStyle="pb-5"
          />
           <SettingItem
            icon={snooze_membership}
            label="Snooze Membership"
            lastItem
            customStyle="pt-5"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Membership;
