import React from "react";
import PropTypes from "prop-types";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import { useNavigate } from "react-router-dom";
import Lock_icon from "../../../assets/imgs/lock-icon.svg";

interface FeatureCardProps {
  imageSrc: string;
  title: string;
  arrowSrc: string;
  link: string;
  comingSoon?: boolean;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  imageSrc,
  title,
  arrowSrc,
  link,
  comingSoon,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="w-[164px] md:w-[200.655px] xl:w-[240.655px] 2xl:w-[302.655px] p-[10px] md:p-3 xl:p-4 2xl:p-[18px] rounded-2xl shrink-0 "
      style={{
        background: Colors.backgroundGraySecondory,
        cursor: comingSoon ? "not-allowed" : "pointer",
      }}
      onClick={() => {
        !comingSoon && navigate(link);
      }}
    >
      <div
        className="w-[44px] md:w-[60px] xl:w-[65px] 2xl:w-[80px] h-[44px] md:h-[60px] xl:h-[65px] 2xl:h-[80px] rounded-full flex justify-center items-center"
        style={{
          backgroundColor: Colors.backgroundGraySecondory,
          opacity: comingSoon ? "0.55" : "1",
        }}
      >
        <img src={imageSrc} alt="" className="w-[24px] md:w-[30.875px] xl:w-[36.875px] 2xl:w-[44.875px]" />
      </div>
      <div
        className=" justify-between items-end mt-5"
        style={{
          display: !comingSoon ? "flex" : "",
        }}
      >
        <Paragraph
          text={title}
          props={{
            width: comingSoon ? "100%" : "50%",
            opacity: comingSoon ? "0.55" : "1",
          }}
          className="text-base md:text-[20.527px] xl:text-[20.527px] 2xl:text-[29.527px] leading-[135%] font-medium"
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div>
          {!comingSoon ? (
            <img src={arrowSrc} alt="" className="rotate-45 w-6 md:w-[28.291px] xl:w-[34.291px] 2xl:w-[44.291px]" />
          ) : (
            <div className="mt-1">
              <Paragraph
                text={"Coming Soon!"}
                className="text-[10px] md:text-[14px] xl:text-base 2xl:text-[18px] leading-[130%] font-medium opacity-50"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
