import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { OnBoarding } from "../OnBoarding";
import { Login } from "../Login"; // Assuming you have a Login component
import { isMobile } from "react-device-detect";

const HandleAuth: React.FC = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { useAuthStore, getProfileForDetails, getSessionwitAccessToken } =
    useSupabaseAuth();
  const user = useAuthStore((state: any): any => state.user);
  const [loading, setLoading] = useState(true);

  const checkUserDetail = async (user_id: string) => {
    const profile = await getProfileForDetails(user_id);

    if (profile && profile.user_name && profile.user_email) {
      navigate("/home");
    } else {
      navigate("/user-details");
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (user?.access_token) {
        console.log("User is authenticated, checking profile details");
        checkUserDetail(user?.user?.id);
      } else {
        const hash = window.location.hash;
        console.log("URL hash:", hash);
        const params = new URLSearchParams(hash.replace("#", ""));
        const accessTokenTemp = params.get("access_token");

        if (accessTokenTemp) {
          setAccessToken(accessTokenTemp);
          const session = await getSessionwitAccessToken(accessTokenTemp);
          const provider = session?.user?.app_metadata?.provider;
          console.log("Provider:", provider);
          // HARDCODED NEED REVIEW OF THE SAVE OBJECT
          if (session?.user) {
            await useAuthStore.getState().setUser({
              ...session?.user,
              access_token: accessTokenTemp,
            });
          }

          let user_id = null;

          if (session?.user) {
            user_id = session?.user?.id;
          } else {
            user_id = user?.user?.id ? user?.user?.id : user?.id;
          }
          checkUserDetail(user_id);
        } else {
          console.log("No access token found in URL hash.");
          if (isMobile) {
            navigate("/on-boarding");
          } else {
            navigate("/welcome");
          }
        }
      }
      setLoading(false);
    };

    checkAuth();
  }, [user]);

  if (loading) {
    console.log("handleAuth loading");
    return <div>Loading...</div>;
  }

  if (!accessToken && !user) {
    return null; // Prevents unnecessary rendering
  }

  return null;
};

export default HandleAuth;
