import { Colors } from "../Commons/Colors/Colors";
import TeamIcon from "../.././/assets/imgs/team-logo.png";
import ArrowRigthIcon from "../.././/assets/imgs/arrow-rigth.png";

import { Paragraph } from "../Commons/Paragraph";

export const TeamAnalizer: React.FC = () => {
  return (
    <div className="flex flex-col py-[24px] w-full">
      <div
        className="mx-12 p-3"
        style={{
          background: Colors.backgroundSecundary,
          borderRadius: "14px",
        }}
      >
        <div className="flex h-[71px] py-3 px-2">
          <div className="flex flex-1">
            <div
              className="flex items-center py-3 px-2"
              style={{ background: Colors.textPrimary, borderRadius: "10px" }}
            >
              <img className="" src={TeamIcon} alt="" />
            </div>

            <div className="flex flex-col ml-3 gap-1">
              <Paragraph
                text="L. Jackson"
                props={{ fontSize: "16px" }}
                ParagraphType="PragraghSemiBold600"
                color="textPrimary"
              />
              <Paragraph
                text="Juwtous League"
                props={{
                  fontSize: "14px",
                  lineHeight: "100%",
                }}
                ParagraphType="PragraghSemiBold400"
                color="textLigth"
              />
            </div>
          </div>
          <div
            className="flex items-center justify-center p-4"
            style={{
              borderRadius: "100px",
              background: Colors.backgroundSecundary,
            }}
          >
            <img src={ArrowRigthIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
