import React, { useState } from "react";
import OtpInput from "react-otp-input";
import ErrorIcon from "../../../assets/imgs/invalide-error-icon.svg";

interface OTPInputProps {
  value: string;
  onChange: (otp: string) => void;
  numInputs: number;
  correctOTP: string | null;
}

const OTPInputComponent: React.FC<OTPInputProps> = ({
  value,
  onChange,
  numInputs,
  correctOTP,
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = (otp: string) => {
    onChange(otp);
    if (otp.length === numInputs) {
      if (correctOTP) {
        setError("Invalid Verification code.");
      } else {
        setError(null);
      }
    }
  };

  const renderInput = (inputProps: any, index: number) => (
    <input
      key={index}
      {...inputProps}
      style={{
        maxWidth: "65px",
        maxHeight: "65px",
        width: "12vw",
        height: "12vw",
        fontSize: "24px",
        borderRadius: "50px",
        border: error ? "1px solid #F11E44" : "1px solid #ccc",
        background: "rgba(255, 255, 255, 0.10)",
        marginRight: "12px",
        textAlign: "center",
        color: "white",
      }}
      className=" focus:border-none focus:outline-none"
    />
  );

  return (
    <div>
      <OtpInput
        value={value}
        onChange={handleChange}
        numInputs={numInputs}
        renderInput={renderInput}
        inputType="tel"
      />
      {error && (
        <div className="flex items-center text-[#F11E44] font-medium text-[14px] gap-[6px] mt-5">
          {" "}
          <img src={ErrorIcon} alt="ErrorIcon" /> {error}
        </div>
      )}
    </div>
  );
};

export default OTPInputComponent;
