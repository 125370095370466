import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApplePaymentAuth } from "../Context/AppleContext/AppleContext";
import { isMobile } from "react-device-detect";

const usePaywall = () => {
  const navigate = useNavigate();
  const { isAppSubscribedMontly, isAppSubscribedYearly, platform } =
    useApplePaymentAuth();

  useEffect(() => {
    const payWall = () => {
      if (isMobile && platform && platform === "ios") {
        if (!isAppSubscribedMontly && !isAppSubscribedYearly) {
          navigate("/chatbot-paywall");
        }
      }
    };

    payWall();
  }, [isMobile, isAppSubscribedMontly, isAppSubscribedYearly]);
};

export default usePaywall;
