import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar: React.FC<{
  percentage: number;
  pathColor: string;
  className?: string
}> = ({ percentage, pathColor, className = "w-20 h-20" }) => {
  console.log("percentage", percentage);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    return setProgress(parseInt(`${percentage}`, 10));
  }, [percentage]);

  return (
    <div className={` ${className}`}>
      <CircularProgressbar
        value={progress}
        text={`${Number.isNaN(progress) ? "NA" : progress}`}
        counterClockwise = {true}
        styles={buildStyles({
          textColor: "white",
          pathColor: pathColor,
          trailColor: "transparent",
          textSize: "42px",
        })}
      />
    </div>
  );
};
export default CircularProgressBar;
