import React, { useState, useEffect } from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router-dom";
import { Paragraph } from "../Commons/Paragraph";
import profile_img from "../.././assets/imgs/research_profile.png";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import Star from "../../assets/imgs/Star.svg";
import { Button } from "../ui/button";
import ResearchOverview from "./ResearchOverview/ResearchOverview";
import ResearchStats from "./ResearchStats/ResearchStats";
import { useParams } from "react-router-dom";
import { PlayerProfileById } from "../../Controllers/PlayerProfileById/index";
import Null_player from "../../assets/imgs/Null_player.png";
import { useSafeAreaInsets } from "../../Context/SafeAreaContext/SafeAreaContext";

const Research = () => {
  const navigate = useNavigate();
  const { top } = useSafeAreaInsets();
  const [selectedTab, setSelectedTab] = useState("overview");
  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };
  const { id } = useParams();
  const [playerId] = useState<string | undefined>(id);
  const [player, setPlayer] = useState<any>();

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await PlayerProfileById(playerId as string);
        setPlayer(response?.data?.data);
      } catch (error) {
        console.error("Error fetching player data:", error);
      }
    };

    if (playerId) {
      fetchPlayerData();
    }
  }, [playerId]);

  return (
    <Layout>
        <div
          className="-mx-5 px-5"
          style={{
            marginTop: `-${top + 20}px`,
            paddingTop: `${top + 20}px`,
            backgroundColor: Colors.backgroundGrayTertiary,
          }}
        >
          <div className="flex items-center justify-between">
            <div
              className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
              style={{
                background: Colors.backgroundSecundary,
              }}
              onClick={() => navigate(-1)}
            >
              <img src={ArrowBackIcon} alt="" />
            </div>
            <Paragraph
              text="Research"
              props={{ fontSize: "22px", fontWeight: 500 }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
            <div className="cursor-pointer rounded-[100px] w-[58px] h-[58px]"></div>
          </div>
          <div className="relative z-40">
            <Paragraph
              text={`${player?.playerFirstName}  ${player?.playerLastName}`}
              props={{
                fontSize: "30px",
                fontWeight: 600,
                lineHeight: "100%",
                marginTop: "36px",
              }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
          <div className="flex justify-between items-end mb-5 -mx-5 px-5 relative">
            <div className="relative z-40">
              {/* <Button
                className="border-[1.5px] max-w-28 text-[10px] gap-1 mt-4 h-10"
                style={{
                  backgroundColor: "#3B3F58",
                  borderColor: Colors.backgroundTertiary,
                }}
              >
                View Insights <img src={Star} alt="" />
              </Button> */}
              <div className="flex gap-3 mb-4 mt-16 flex-wrap">
                <Button
                  className="w-28 h-[42px] text-[14px]"
                  onClick={() => handleTabClick("overview")}
                  style={{
                    backgroundColor:
                      selectedTab === "overview"
                        ? Colors.backgroundPrimary
                        : "#3B3F58",
                  }}
                >
                  Overview
                </Button>
                <Button
                  className="h-[42px]  w-[72px] text-[14px]"
                  onClick={() => handleTabClick("stats")}
                  style={{
                    backgroundColor:
                      selectedTab === "stats"
                        ? Colors.backgroundPrimary
                        : "#3B3F58",
                  }}
                >
                  Stats
                </Button>
              </div>
            </div>
            <img
              src={player?.playerheadshotURL || Null_player}
              alt=""
              className="-ml-[100px] absolute z-10 bottom-0 right-0"
              style={{
                maxWidth: "244.157px",
                maxHeight: "259.016px",
              }}
              onError={(e) => {
                (e.currentTarget as HTMLImageElement).src = Null_player;
              }}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </div>
      <div className="">
        {selectedTab === "overview" ? (
          <ResearchOverview data={player} />
        ) : (
          <div className="-mx-5">
            <ResearchStats
              statsData={player?.statsSummary}
              weeklyStats={player?.weeklyStats}
              playerPosition={player?.playerPosition}
            />
          </div>
            
        )}
      </div>
    </Layout>
  );
};

export default Research;
