import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP__PLAYER_API_BASE_URL,
  transformRequest: [
    (data, headers): string => {
      return JSON.stringify(data);
    },
  ],
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    // config.headers["UserAuthorization"] = "Bearer " + getRawAccessToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const {
      response: { status, config },
    } = error;
    if (status === 401 && config.headers.Authorization) {
      //lscache.set(AUTH_TOKEN, '')
      //history.go(/login)
    } else {
      throw new Error(error.response.data.detail);
    }
  },
);

export default instance;
