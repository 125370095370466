import httpRequest from './HttpClient';

interface HttpRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  headers?: { [key: string]: string };
  params?: { [key: string]: string };
  data?: any;
}

const httpRequestChat = async (options: HttpRequestOptions): Promise<any> => {
  try {
    return await httpRequest(options);
  } catch (error) {
    console.log(error);
  }
};

export default httpRequestChat;
