import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Colors } from '../Colors/Colors';

interface SkeletonLoaderProps {
  count?: number;
  className?: string;
  isLoading: boolean; 
  bgColor?: string;
  children: React.ReactNode;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  count = 1,
  className = '',
  isLoading,
  bgColor=Colors.backgroundSecundary,
  children,
  ...rest
}) => {
  if (isLoading) {
    return (
      <Skeleton
        count={count}
        className={className}
        highlightColor={Colors.backgroundSecundary}
        baseColor={Colors.backgroundSecundary}
        style={{background: bgColor}}
        {...rest}
      />
    );
  }

  return <>{children}</>;
};

export default SkeletonLoader;
