import HttpRequestPlayerProfile from "../../Services/HttpRequestPlayerProfile";
import { useQuery } from "@tanstack/react-query";

export const getPlayersRanking = async () => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "GET",
      url: "/v1/rankings_season_leaders/",
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const getRankingThisWeek = async () => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "GET",
      url: "/v1/rankings_this_weeks/",
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const usePlayersRanking = () => {
  return useQuery({
    queryKey: ["cachePlayersProfile"],
    queryFn: getPlayersRanking,
    staleTime: 15 * 60 * 1000, //15 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
};

export const useRankingThisWeek = () => {
  return useQuery({
    queryKey: ["getRankingThisWeek"],
    queryFn: getRankingThisWeek,
    staleTime: 15 * 60 * 1000, //15 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
};


export const getDraftRankings = async () => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "GET",
      url: "/v1/rankings_draft/",
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const useDraftRankings = () => {
  return useQuery({
    queryKey: ["getDraftRankings"],
    queryFn: getDraftRankings,
    staleTime: 15 * 60 * 1000, //15 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
};