import React, { useEffect, useState } from "react";
import MenuLineIcon from "../../assets/imgs/menu-line.svg";
import PlayersCard from "../Rankings/PlayersCard/PlayersCard";
import player_icon from "../../assets/imgs/player_icon.png";
import Null_player from "../../assets/imgs/null-player-img.svg";
import { Colors } from "../Commons/Colors/Colors";
import Daimond_icon from "../../assets/imgs/Vector.svg";
import BotIcon from "../../assets/imgs/logo-02 2.svg";
import Arrow_trending_up from "../../assets/imgs/arrow-trending-up.svg";
import useUserProfile from "../../hooks/useUserProfile";
import { Button } from "../ui/button";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";

const StockWatchSidebar: React.FC<{
  dummies: {
    roto_player_id: string;
    Name: string;
    POS: string;
    url: string;
    rosRank: string;
    arr_img: string;
    Team: string;
  }[];
}> = ({ dummies }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { firstName, profile } = useUserProfile();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { getUserData } = useSupabaseAuth();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const response = await getUserData();

      if (response) {
        setCurrentUser(response?.data?.user);
      }
    };
    fetchCurrentUser();
  }, []);

  return (
    <div className="sm:block hidden" style={{ backgroundColor: "#FFFFFF0D" }}>
      <div
        className={`h-screen text-white transition-transform transform z-40 flex flex-col`}
      >
        <div
          className={`flex items-end justify-between border-b-2 border-[${Colors.backgroundSecundary}] p-4 h-24 mt-8`}
        >
          <div className="flex flex-row gap-5">
            <img src={BotIcon} alt="logo" className="h-13 " />
          </div>
        </div>

        <div className="py-3 px-5 flex-1 overflow-y-auto">
          <h3 className="text-lg font-semibold mb-4">Stock Watch</h3>
          <div className="flex flex-col gap-8">
            {dummies.map((card, index) => (
              <div key={index}>
                <PlayersCard
                  arrowImage={Arrow_trending_up}
                  playerImage={card.url}
                  playerName={card.Name}
                  playerPosition={card.POS}
                  rosRank={card.rosRank}
                  opponent={card.Team}
                  id={card.roto_player_id}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={`w-full p-4 mt-auto bg-[#FFFFFF0F]`}>
          <Button className="w-full py-2 mb-4 text-center gap-1 justify-center  rounded-3xl flex flex-row h-[50px]">
            <img className="w-4 h-5 " src={Daimond_icon} alt="diamond" />
            Go Premium
          </Button>
          <div className="flex items-center">
            <img
              className="w-12 h-12 rounded-full"
              src={
                currentUser?.user_metadata?.avatar_url
                  ? currentUser?.user_metadata?.avatar_url
                  : Null_player
              }
              alt="User"
            />
            <span className="ml-4">{firstName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockWatchSidebar;
