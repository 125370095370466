import React, { useEffect, useState } from "react";
import { Button } from "../ui/button";
import RatingStar from "../Commons/Rating/Rating";
import { Paragraph } from "../Commons/Paragraph";
import { Textarea } from "../ui/textarea";
import { Colors } from "../Commons/Colors/Colors";

import { createFeedBack } from "../../Controllers/Feedback";
import { AnimatePresence, motion } from "framer-motion";
import cancelIcon from "../../assets/imgs/cancel-icon.png";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";

interface Props {
  setOpen: (isOpen: boolean) => void;
  open: boolean;
}

const FeedbackModal = ({ setOpen, open }: Props) => {
  const [ratings, setRatings] = useState({
    accuracy: 0,
    responseTime: 0,
    easeOfUse: 0,
    helpfulness: 0,
  });
  const [message, setMessage] = useState("");
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { getUserData } =
  useSupabaseAuth();

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleCreateFeedBack = async () => {
    const response = await createFeedBack(
      currentUser?.id, 
      ratings.accuracy,
      ratings.responseTime,
      ratings.easeOfUse,
      ratings.helpfulness,
      message,
    );

    if (response.status) {
      setOpen(false);
      setMessage("");
      setRatings({
        accuracy: 0,
        responseTime: 0,
        easeOfUse: 0,
        helpfulness: 0,
      });
    } else {
      console.log("something wrog with the Feedback, contact support please!!");
    }
  };

  const handleRatingChange = (key: string, value: number) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [key]: value,
    }));
  };

  return (

    <AnimatePresence>
      {open && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-30"

          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
          }}
        >
          <motion.div
            className="text-white flex justify-center items-end h-full p-[14px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >

            <div
              
              className="fixed left-[50%] top-[50%] z-50 grid max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 bg-[#181A2078] border border-[#ffffff1a] backdrop-blur-[75px] p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-[20px] w-11/12"
            >

              <Paragraph
                text={"Feedback"}
                props={{ fontSize: "15px", fontWeight: "500" }}
                ParagraphType="Pragragh22"
                color="textPrimary"
                className="text-xl"
              />
              <div
                className="cursor-pointer items-center right-5 top-7 absolute"
                onClick={() => setOpen(false)}
              >
                <img src={cancelIcon} alt="" />
              </div>
              
              <div className="flex items-center space-x-2">
                <div className="flex flex-col gap-8 w-full mt-3">
                  <RatingStar
                    label="Accuracy"
                    value={ratings.accuracy}
                    onChange={(value) => handleRatingChange("accuracy", value)}
                  />
                  <RatingStar
                    label="Response Time"
                    value={ratings.responseTime}
                    onChange={(value) => handleRatingChange("responseTime", value)}
                  />
                  <RatingStar
                    label="Ease of Use"
                    value={ratings.easeOfUse}
                    onChange={(value) => handleRatingChange("easeOfUse", value)}
                  />
                  <RatingStar
                    label="Helpfulness"
                    value={ratings.helpfulness}
                    onChange={(value) => handleRatingChange("helpfulness", value)}
                  />
                </div>
              </div>

              <div className="mt-2">
                <Paragraph
                  text={"Any additional feedback"}
                  props={{ fontSize: "15px", fontWeight: "500" }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />

                <Textarea
                  className={`bg-[${Colors.backgroundSecundary}] border-0 focus:ring-0 mt-2 text-white text-base h-32 placeholder:text-[${Colors.backgroundSecundary}]`}
                  placeholder="Start typing here..."
                  onChange={(event) => setMessage(event.target.value)}
                  value={message}
                />

                <Button className=" mt-6" onClick={handleCreateFeedBack}>
                  Submit
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>

  );
};

export default FeedbackModal;
