import { ReactNode, useEffect } from "react";
import { Footer } from "../Chatbot/Footer";
import { Header } from "../Chatbot/Header";
import { CSSProperties, useState, KeyboardEvent, Children } from "react";

//icons
import BgMobile from "../../assets/imgs/BG.png";

//color paletee
import { Colors } from "../Commons/Colors/Colors";

//components
import MenuMobile from "../Chatbot/MenuMobile";

//Type
import { ChatItem } from "../Chatbot/type";

import { TYPECHAT } from "../../config/constants/constants";
import { useSafeAreaInsets } from "../../Context/SafeAreaContext/SafeAreaContext";
import ChatbotSidebar from "../Chatbot/ChatbotSidebar/ChatbotSidebar";

const dataItems = [
  { id: 0, title: "Should I start Puka Nacua or Deebo this week in half-PPR?" },
  { id: 1, title: "How will Dak Prescott do this season?",},
  { id: 2, title: "Give me Garrett Wilson's weekly outlook?" },
  { id: 3, title: "What's the season recap for Nico Collins?" },
  { id: 4, title: "Who are some mid round sleepers that have breakout potential in 2024?" },
];

export interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { top, bottom, left, right } = useSafeAreaInsets();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (feedbackModal) {
      setIsOpen(false);
    }
  }, [feedbackModal]);

  return (
    <div
      style={{
        background: `#000513 url(${BgMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: top,
        paddingBottom: bottom,
        paddingLeft: left,
        paddingRight: right,
        boxSizing: "border-box",
        height: "100svh",
        width: "100vw",
        overflowY: "auto",
      }}
      className="block sm:flex"
    >
      <div className=" flex-shrink-0">
        <ChatbotSidebar />
      </div>
      <div className="h-full w-full sm:w-[calc(100vw_-240px)] lg:sm:w-[calc(100vw_-_280px)]" >
        <MenuMobile
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setFeedbackModal={setFeedbackModal}
          feedbackModal={feedbackModal}
        />
        <Header onClickMenu={handleMenu} />
        {children}
      </div>
    </div>
  );
};
