import { ChangeEvent } from "react";
import { Input } from "../../ui/input";
import { Colors } from "../../Commons/Colors/Colors";

interface StyledInputProps {
    placeholder: string;
    type?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  }
  
  const StyledInput: React.FC<StyledInputProps> = ({ placeholder, type = "text", onChange }) => (
    <Input
      type={type}
      placeholder={placeholder}
      className={`bg-transparent border-[1px] rounded-2xl py-4 px-5 h-12 placeholder-[${Colors.textLigth}]`}
      style={{ borderColor: Colors.textLigth }}
      onChange={onChange}
    />
  );
  

  export default StyledInput