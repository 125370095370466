// Paragraph.tsx
import React from "react";
import { Props } from "./types";
import { Colors } from "../Colors/Colors";
import { ParagraphStylesProps } from "./StyleType/types";

const StyleType: ParagraphStylesProps = {
  Pragragh22: "not-italic font-medium leading-normal",
  PragraghSemiBold400Normal: "text-base font-normal leading-normal",
  PragraghSemiBold400: "text-base font-normal leading-5",
  PragraghSemiBold600: "font-semibold leading-none",
  PragSemBold600: "leading-tight",
};

const Paragraph: React.FC<Props> = ({ text, props, ParagraphType, color, className }) => {
  return (
    <p
      className={`${StyleType[ParagraphType]} ${className} p-0 m-0`}
      style={{
        color: Colors[color] || Colors.default,
        ...props,
      }}
    >
      {text}
    </p>
  );
};

export default Paragraph;
