import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PrivateRouteProps } from "./type";
import { useSupabaseAuth } from "../Context/AuthContext/AuthContext";
import { isMobile } from "react-device-detect";

const isAuthenticated = (user: any): boolean => {
  return !!user;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { useAuthStore, getProfileForDetails } = useSupabaseAuth();
  const user = useAuthStore((state: any) => state.user);

  useEffect(() => {
    const checkProfile = async () => {
      const user_id = user?.user?.id ? user?.user?.id : user?.id;
      const profile = await getProfileForDetails(user_id);

      if (!profile || !profile.user_name || !profile.user_email) {
        window.location.href = "/user-details";
      }
    };

    if (user) {
      checkProfile();
    }
  }, [user]);

  return isAuthenticated(user) ? (
    <>{children}</>
  ) : isMobile ? (
    <Navigate to="/on-boarding" replace />
  ) : (
    <Navigate to="/welcome" replace />
  );
};
