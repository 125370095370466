import { useSupabaseAuth } from "../../../Context/AuthContext/AuthContext";
import { useApplePaymentAuth } from "../../../Context/AppleContext/AppleContext";
import React, { useEffect } from "react";

const AppletPay = () => {
  const { subscriptions, purchaseProduct } = useApplePaymentAuth();
  const { getUserProfile, getUserData } = useSupabaseAuth();

  const [currentUser, setCurrentUser] = React.useState<any>(null);
  const [isInitilized, setIsInitilized] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState<any>(null);

  React.useEffect(() => {
    const fethProfile = async () => {
      if (currentUser?.id) {
        const responde = await getUserProfile(currentUser?.id);
        setUserProfile(responde);
      }
    };

    if (currentUser?.id) {
      fethProfile();
    }
  }, [currentUser?.id]);

  console.log("currentUser  **** ", currentUser);
  console.log("user_profile  **** ", userProfile);

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    if (!isInitilized) {
      fetchCurrentUser();
      setIsInitilized(true);
    }
  }, [isInitilized]);

  const handlePurchase = (productId: string) => {
    purchaseProduct(userProfile, currentUser?.id, productId);
  };

  return (
    <div className="pt-10 px-5 text-white">
      <h1 className="mb-4 text-2xl text-center">Apple Pay</h1>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              {/* <th className="px-4 py-2 text-left">ID</th> */}
              <th className="px-4 py-2 text-left">Title</th>
              {/* <th className="px-4 py-2 text-left">Description</th> */}
              <th className="px-4 py-2 text-left">Price</th>
              <th className="px-4 py-2 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((product: any) => (
              <tr key={product?.id} className="border-b border-gray-600">
                {/* <td className="px-4 py-2">{product?.id}</td> */}
                <td className="px-4 py-2">{product?.title}</td>
                {/* <td className="px-4 py-2">{product?.description}</td> */}
                <td className="px-4 py-2">{product?.price}</td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handlePurchase(product?.id)}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Buy
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AppletPay;
