// src/contexts/SafeAreaContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { SafeArea } from 'capacitor-plugin-safe-area';

interface SafeAreaInsets {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

const SafeAreaContext = createContext<SafeAreaInsets>({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

export const SafeAreaProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [safeAreaInsets, setSafeAreaInsets] = useState<SafeAreaInsets>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  useEffect(() => {
    const getSafeAreaInsets = async () => {
      const { insets } = await SafeArea.getSafeAreaInsets();
      setSafeAreaInsets(insets);
    };

    getSafeAreaInsets();
  }, []);

  return (
    <SafeAreaContext.Provider value={safeAreaInsets}>
      {children}
    </SafeAreaContext.Provider>
  );
};

export const useSafeAreaInsets = () => useContext(SafeAreaContext);
