import { PushNotifications } from "@capacitor/push-notifications";
import { supabase } from "../supabase/supabase.config";

export const initPushNotifications = async (userId: string | undefined) => {
  //request permissions
  const permisiionStatus = await PushNotifications.requestPermissions();


  if (permisiionStatus.receive === 'granted') {
    console.log("push notifications permission granted");
    await PushNotifications.register();
  } else {
    console.log("push notifications permission denied");
    return
  }

  PushNotifications.addListener('registration', async token => {
    console.log("Push notifications successfully registered, token: ", token.value);
    await saveToken(token.value, userId);
  });

  PushNotifications.addListener('registrationError', error => {
    console.error("registration Error", error.error);
  });

  PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log("push Notification Received : ", notification);
  });

  PushNotifications.addListener("pushNotificationActionPerformed", notification => {
    console.log("push notification action performed : ", notification);
  });
};


  //helper function for save the token
  const saveToken = async (token: string, userId: string | undefined) => {
    const {error} = await supabase.from('profiles').update({fcm_token: token}).eq('user_id',userId);

    if (error){
      console.error("error saving token",error);
    }else {
      console.log("Push notification successfully saved");
    }
  };


