import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Paragraph } from "../Commons/Paragraph";
import { Button } from "../ui/button";
import pop_up_bg from "../../assets/imgs/capabilities-modal.svg";
import tick from "../../assets/imgs/tick.svg";
import comingSoonIcon from "../../assets/imgs/chat-warning.svg";
import { Colors } from "../Commons/Colors/Colors";

interface CapabilitiesModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const features = [
  { name: "Weekly Recap", comingSoon: false },
  { name: "Season Outlook", comingSoon: false },
  { name: "Sit / Start", comingSoon: false },
  { name: "Trade Analysis", comingSoon: false },
  { name: "Stat Research (Coming Soon!)", comingSoon: true },
];

const CapabilitiesModal: React.FC<CapabilitiesModalProps> = ({
  isVisible,
  onClose,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-30 flex justify-center"
          onClick={onClose}
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
          }}
        >
          <motion.div
            className="text-white flex justify-center items-end h-full p-[14px] max-w-[500px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div
              style={{
                borderRadius: "20px",
                background: "#212130",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <img
                src={pop_up_bg}
                alt="CapabilitiesModal Background"
                className="rounded-t-[20px] w-full max-h-[300px]"
              />
              <div className="mt-5 text-center p-2">
                <Paragraph
                  text="RotoBot’s Current Capabilities"
                  props={{
                    fontSize: "23px",
                    fontWeight: "600",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                  className="mb-1.5"
                />
                <Paragraph
                  text="Discover what RotoBot can do for you right now! Stay tuned for more exciting capabilities coming soon, and feel free to ask us about future enhancements."
                  props={{
                    fontSize: "14px",
                    fontWeight: "400",
                    opacity: "0.50",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                />
                <div className="px-3 py-5 mt-4 rounded-xl flex flex-col gap-4">
                  {features.map((feature, index) => (
                    <div className="flex gap-3" key={index}>
                      <div
                        className="w-[28.25px] h-[28.25px] rounded-full flex justify-center items-center flex-shrink-0"
                        style={{ backgroundColor: feature.comingSoon ? Colors.bgNegativeRed : Colors.backgroundTertiary }}
                      >
                        <img
                          src={feature.comingSoon ? comingSoonIcon : tick}
                          alt=""
                        />
                      </div>
                      <div className="mt-[6px]">
                        <Paragraph
                          text={feature.name}
                          props={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "120%",
                          }}
                          ParagraphType="Paragraph22"
                          color="textPrimary"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Button className="text-[13.5px] font-medium mt-6 mb-5 w-[80%]">
                  Get Started
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CapabilitiesModal;
