import React, { useEffect } from "react";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import firebase from 'firebase/compat/app';

const firebaseConfig = {
 apiKey: "AIzaSyDgwumdff20OvuC4lgZc1-FuiAVuH8LmDc",
 authDomain: "rotobot-ai.firebaseapp.com",
 projectId: "rotobot-ai",
 storageBucket: "rotobot-ai.appspot.com",
 messagingSenderId: "62104652584",
 appId: "1:62104652584:web:f219756c3a769f2aa2de1d",
 measurementId: "G-JHP3WK1GPS",
};

const FirebaseAnalyticsService: React.FC = () => {
 useEffect(() => {
  const initializeFirebaseWeb = async () => {
   if (!firebase.apps.length) {
    const app = initializeApp(firebaseConfig);
    if (await isSupported()) {
     getAnalytics(app);
    }
   }
  };

  const initializeFirebaseMobile = async () => {
   await FirebaseAnalytics.setCollectionEnabled({
    enabled: true,
   });

   await FirebaseAnalytics.initializeFirebase({
    apiKey: firebaseConfig.apiKey,
    authDomain: firebaseConfig.authDomain,
    projectId: firebaseConfig.projectId,
    storageBucket: firebaseConfig.storageBucket,
    messagingSenderId: firebaseConfig.messagingSenderId,
    appId: firebaseConfig.appId,
    measurementId: firebaseConfig.measurementId,
   });
  };

  initializeFirebaseWeb().catch((error) => {
   console.error("Firebase Analytics web initialization error:", error);
  });

  initializeFirebaseMobile().catch((error) => {
   console.error("Firebase Analytics mobile initialization error:", error);
  });

 }, []);

 const getAppInstanceId = async () => {
  try {
   const response = await FirebaseAnalytics.getAppInstanceId();
   const { instanceId } = response;
   console.log('Instance ID: ' + instanceId);
  } catch (error) {
   console.error("Error getting app instance ID:", error);
  }
 };

 return null;
};

export default FirebaseAnalyticsService;
