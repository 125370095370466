import React from 'react';
import { Colors } from '../Colors/Colors';

interface ToggleProps {
  isChecked: boolean;
  onToggleChange?: () => void;
}

const ToggleBtn: React.FC<ToggleProps> = ({ isChecked, onToggleChange }) => {
  return (
    <div className="flex items-center justify-center">

        <div className="relative" onClick={onToggleChange}>

          <div className="w-[50px] h-6 rounded-full shadow-inner" style={{
            backgroundColor: Colors.backgroundSecundary
          }}></div>
          <div className={`dot absolute w-[22px] h-[22px] rounded-full shadow left-[2px] top-[1px] transition-all ease-in-out duration-300 ${!isChecked ? ' translate-x-0 bg-white' : `translate-x-6 `}`} style={{
            backgroundColor: isChecked? Colors.bgGreenPrimary : "white"
          }}></div>
        </div>

    </div>
  );
}

export default ToggleBtn;
