import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { InAppPurchase2 as iap } from "@ionic-native/in-app-purchase-2";
import { supabase } from "../../supabase/supabase.config";
import { Device } from "@capacitor/device";

interface AuthContextType {
  subscriptions: any;
  getMonthlySubscription: () => string;
  getYearlySubscription: () => string;
  purchaseProduct: (userProfile: any, userId: string, productId: string) => any;
  isActiveSubscription: () => any;
  isAppSubscribedMontly: boolean;
  isAppSubscribedYearly: boolean;
  restore: () => void;
  platform: string | null | undefined;
}

import {
  MONTLY_SUBSCRIPTION,
  YEARLY_SUBSCRIPTION,
} from "../../Components/Subscription/PaymentMethod/constant";
import { isMobile } from "react-device-detect";

interface AuthContextProp {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const ApplePayContextProvider: React.FC<AuthContextProp> = ({
  children,
}) => {
  const [isInitilized, setIsInitilized] = React.useState(false);
  const [subscriptions, setSubscriptions] = React.useState<any[]>([]);
  const [isAppSubscribedMontly, setIsAppSubscribedMontly] =
    React.useState(false);
  const [isAppSubscribedYearly, setIsAppSubscribedYearly] =
    React.useState(false);
  const [platform, setPlatform] = React.useState<string | null | undefined>(
    null,
  );

  const loadAndRegisterProducts = async () => {
    console.log("loadAndRegisterProducts ApplePayContextProvider");

    if (process.env.REACT_APP_MOBILE_DEBUG === "true") {
      iap.verbosity = iap.DEBUG;
    }

    console.log("Setting the verbosity to debug");

    registerProducts();
    setupListeners();

    iap.ready(() => {
      console.log("Store is ready, loading the subscriptions");
      setSubscriptions(iap.products);
    });

    iap.refresh();
    console.log("isActiveSubscription default false ");
  };

  console.log("ApplePayContextProvider  **** ", subscriptions);

  if (isMobile && platform && platform === "ios") {
    document.addEventListener("deviceready", loadAndRegisterProducts, false);
  }

  const identifyPlatform = async () => {
    const info = await Device.getInfo();
    setPlatform(info.platform);
    return info.platform;
  };

  useEffect(() => {
    if (!platform) {
      identifyPlatform();
    }

    if (!isInitilized && isMobile && platform && platform === "ios") {
      document.addEventListener("deviceready", loadAndRegisterProducts, false);
      // loadAndRegisterProducts();
      setIsInitilized(true);
    }

    // return () => {
    //   document.removeEventListener(
    //     "deviceready",
    //     loadAndRegisterProducts,
    //     false,
    //   );
    // };
  }, [platform]);

  const registerProducts = () => {
    iap.register([
      {
        id: MONTLY_SUBSCRIPTION,
        alias: "Monthly Subscription",
        type: iap.PAID_SUBSCRIPTION,
      },
      {
        id: YEARLY_SUBSCRIPTION,
        alias: "Yearly Subscription",
        type: iap.PAID_SUBSCRIPTION,
      },
    ]);
    console.log("Registering the yearly and montly subscription");

    if (process.env.REACT_APP_VALIDATOR_URL_BACKEND) {
      iap.validator = process.env.REACT_APP_VALIDATOR_URL_BACKEND;
      console.log("Add validator backend");
    }

    iap.refresh();
    console.log("Refreshing the products");
  };

  const setupListeners = useCallback(() => {
    console.log("setupListeners ApplePayContextProvider");
    restore();
    iap.when(MONTLY_SUBSCRIPTION).updated((product: any) => {
      console.log(" MONTLY_SUBSCRIPTION iap When updated ** ", product);
      if (product.owned) {
        // User is subscribed, do something
        console.log("MONTLY_SUBSCRIPTION User is subscribed, do something");
        setIsAppSubscribedMontly(true);
      } else {
        console.log("MONTLY_SUBSCRIPTION User is not subscribed, do something");
        setIsAppSubscribedMontly(false);
      }
      console.log("Setting up the listeners");
    });

    iap.when(YEARLY_SUBSCRIPTION).updated((product: any) => {
      console.log(" YEARLY_SUBSCRIPTION iap When updated ** ", product);
      if (product.owned) {
        // User is subscribed, do something
        console.log("YEARLY_SUBSCRIPTION User is subscribed, do something");
        setIsAppSubscribedYearly(true);
      } else {
        console.log("YEARLY_SUBSCRIPTION User is not subscribed, do something");
        setIsAppSubscribedYearly(false);
      }
    });

    iap.when("subscription status").updated(function (subscription: any) {
      if (subscription.expired) {
        setIsAppSubscribedYearly(false);
        setIsAppSubscribedMontly(false);
        console.log("subscription is not active.", subscription);
      } else {
        console.log("suscription is active.", subscription);
      }
    });
  }, []);

  const getMonthlySubscription = () => {
    return MONTLY_SUBSCRIPTION;
  };

  const getYearlySubscription = () => {
    return YEARLY_SUBSCRIPTION;
  };

  const purchaseProduct = (
    userProfile: any,
    userId: string,
    productId: string,
  ) => {
    iap
      .order(productId)
      .then(() => {
        console.log("product purchased started");
        iap
          .when(productId)
          .approved((product: any) => {
            console.log("verify product ** ", product);
            product.verify();
          })
          .verified((product: any) => {
            console.log("product finished ** ", product);

            const saveProfileSubscription = async () => {
              console.log("previous saveAppleSubscription ** ");
              await saveAppleSubscription(userProfile, userId, product);
              console.log("after saveAppleSubscription ** ");
            };

            saveProfileSubscription();
            console.log("Subscription purchased successfully");
            product.finish();
            iap.refresh();
            window.location.href = "/chat/new";
          });
      })
      .catch((error: any) => {
        console.log("Error purchasing product " + error);
      });
  };

  const saveAppleSubscription = async (
    userProfile: any,
    userId: string,
    product: any,
  ) => {
    // const userProfile = await getUserProfile(userId);
    console.log("saveAppleSubscription => product", product);
    console.log(
      "saveAppleSubscription => JSON.stringify(product)",
      JSON.stringify(product),
    );

    if (userProfile) {
      const { data, error } = await supabase
        .from("profiles")
        .update({
          apple_subscription: JSON.stringify(product),
        })
        .eq("user_id", userId);

      if (error) {
        console.error("[UPDATE] error creating apple subscription", error);
      } else {
        console.log("[UPDATE] apple subscription saved", data);
      }
    } else {
      const { data, error } = await supabase.from("profiles").insert({
        user_id: userId,
        apple_subscription: JSON.stringify(product),
      });

      if (error) {
        console.error("[INSERT] error saving apple subscription", error);
      } else {
        console.log("[INSERT] apple subscription update", data);
      }
    }
  };

  const isActiveSubscription = () => {
    if (isMobile) {
      restore();
      iap.when(MONTLY_SUBSCRIPTION).updated((product: any) => {
        console.log(" iap When updated ** ", product);
        if (product.owned) {
          // User is subscribed, do something
          console.log("User is subscribed, do something");
          setIsAppSubscribedMontly(true);
        } else {
          // User is not subscribed, do something
          console.log("User is not subscribed, do something");
          setIsAppSubscribedMontly(false);
        }
      });

      iap.when(YEARLY_SUBSCRIPTION).updated((product: any) => {
        console.log(" iap When updated ** ", product);
        if (product.owned) {
          // User is subscribed, do something
          console.log("User is subscribed, do something");
          setIsAppSubscribedYearly(true);
        } else {
          // User is not subscribed, do something
          console.log("User is not subscribed, do something");
          setIsAppSubscribedYearly(false);
        }
      });
    }
  };

  const restore = () => {
    iap.refresh();
  };

  return (
    <AuthContext.Provider
      value={{
        subscriptions,
        getMonthlySubscription,
        getYearlySubscription,
        purchaseProduct,
        isActiveSubscription,
        isAppSubscribedMontly,
        isAppSubscribedYearly,
        restore,
        platform,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useApplePaymentAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an ApplePayContextProvider");
  }
  return context;
};
