import { useSafeAreaInsets } from "../../Context/SafeAreaContext/SafeAreaContext";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import CircularProgressBar from "../Commons/CircularProgressBar/CircularProgressBar";
import LineChart from "../Commons/lineChart/lineChart";
import MetricPanel from "./MetricPanel/MetricPanel";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import arrow from "../../assets/imgs/arrow_primary_icon.svg";
import upload_icon from "../../assets/imgs/upload_cion.svg";
import BgMobile from "../../assets/imgs/BG.png";
import { useNavigate } from "react-router-dom";

interface PlayerProfileDetailsProps {
  item: any;
  onClose: () => void;
}

const PlayerProfileDetails: React.FC<PlayerProfileDetailsProps> = ({
  item,
  onClose,
}) => {
  const navigate = useNavigate();
  const { top, bottom, left, right } = useSafeAreaInsets();

  return (
    <div
      className="flex flex-wrap gap-4 justify-center items-center pb-5 fixed top-0 left-0 w-screen h-full z-[1000]"
      style={{
        background: `#000513 url(${BgMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className=" overflow-y-auto h-screen overflow-x-hidden"
        style={{
          backgroundColor: Colors.backgroundSecundary,
        }}
      >
        <div className="w-screen h-full rounded-xl p-4 relative flex flex-col ">
          <div
            style={{
              paddingTop: top,
              paddingBottom: bottom,
              paddingLeft: left,
              paddingRight: right,
            }}
          >
            <div className="p-5 -mx-5 -my-5">
              <div>
                <div className="flex items-center justify-between">
                  <div
                    className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
                    style={{
                      background: Colors.backgroundSecundary,
                    }}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <img src={ArrowBackIcon} alt="" />
                  </div>
                  <div className="flex items-center gap-3">
                    <Paragraph
                      text={item.cardTitle}
                      props={{
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                      ParagraphType="Pragragh22"
                      color="textPrimary"
                    />
                    <div
                      className="border-[2px] rounded-[20px] flex items-center gap-1 py-1 px-2"
                      style={{
                        borderColor:
                          parseInt(item.scoreDelta) >= 0
                            ? Colors.bgGreenPrimary
                            : "#F11E84",
                      }}
                    >{!isNaN(parseFloat(item.scoreDelta)) && <img src={arrow} alt=""  style={{
                      transform: parseFloat(item.scoreDelta) < 0 ? "rotate(180deg)" : "none",
                    }} />}
                      <Paragraph
                        text={
                          !isNaN(parseFloat(item.scoreDelta))
                          ? parseFloat(item.scoreDelta).toFixed(2)
                          : isNaN(item.scoreDelta)
                            ? "NA"
                            : item.scoreDelta.replace("+", " ").replace("-", " ") || "--"
                        }
                        props={{
                          fontSize: "12px",
                        }}
                        ParagraphType="Pragragh22"
                        color="textPrimary"
                      />
                    </div>
                  </div>
                  <div
                    className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
                    style={{
                      background: Colors.backgroundSecundary,
                    }}
                  >
                    <img src={upload_icon} alt="" />
                  </div>
                </div>
                {item.scoreHistory?.length > 0 ? (
                  <div className="flex items-center gap-4 mb-6 mt-5">
                    <div>
                      <CircularProgressBar
                        percentage={item.score}
                        pathColor={item.cardColor}
                      />
                    </div>
                    <div className="flex-1">
                      <LineChart
                        chartdata={item.scoreHistory}
                        color={item.cardColor}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 mb-16">
                    <Paragraph
                      text={"--No Data Currently--"}
                      props={{
                        fontSize: "20px",
                        lineHeight: "145%",
                        letterSpacing: "-0.4px",
                        opacity: "0.70",
                      }}
                      ParagraphType="Pragragh22"
                      color="textPrimary"
                    />
                  </div>
                )}
                <Paragraph
                  text={item.blurbShort}
                  props={{
                    fontSize: "10px",
                    color: Colors.textLigth,
                    lineHeight: "145%",
                    letterSpacing: "-0.2px",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
              </div>
              <div className="mt-6">
                <Paragraph
                  text={item.blurbLong}
                  props={{
                    fontSize: "10px",
                    color: Colors.textLigth,
                    lineHeight: "145%",
                    letterSpacing: "-0.21px",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <div className="flex flex-col gap-12 mt-11">
                  {item.statCategories?.length > 0 &&
                    item.statCategories.map((subitem: any, index: number) => (
                      <MetricPanel
                        key={index}
                        heading={item.cardTitle}
                        statCategories={subitem}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfileDetails;
