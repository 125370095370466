import { useState } from 'react';
import { SpeechRecognition } from '@capacitor-community/speech-recognition';

const useSpeechRecognition = () => {
  const [transcript, setTranscript] = useState<string>('');
  const [isListening, setIsListening] = useState<boolean>(false);

  const requestPermission = async (): Promise<boolean> => {
    const permission = await SpeechRecognition.requestPermissions();
    if (!permission) {
      console.log('Permission not granted!');
      return false;
    }
    return true;
  };

  const startListening = async () => {
    const permissionGranted = await requestPermission();
    if (!permissionGranted) return;

    const options = {
      language: 'en-US',
      matches: 1,
      prompt: 'Say something',
      showPopup: true,
    };

    try {
      setIsListening(true);
      const result = await SpeechRecognition.start(options);
      if (result?.matches?.length) {
        setTranscript(result.matches[0]);
      } else {
        console.log('No speech detected');
      }
    } catch (error: any) {
      console.log(`Error: ${error.message}`);
    }
  };

  const stopListening = async () => {
    try {
      await SpeechRecognition.stop();
      setIsListening(false);
    } catch (error: any) {
      console.log(`Error: ${error.message}`);
    }
  };

  return {
    transcript,
    isListening,
    startListening,
    stopListening,
    setTranscript
  };
};

export default useSpeechRecognition;
