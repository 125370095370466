import Arrow_trending from "../../assets/imgs/arrow-trending-up.svg";
import Arrow_trending_down from "../../assets/imgs/arrow-trending-down.svg";
import player_icon2 from "../../assets/imgs/stock-img-1.svg";
import player_icon from "../../assets/imgs/stock-img-2.svg";
import { Root } from "@radix-ui/react-slot";

export const headers = [
  {
    headerType: "This Week",
    position:"QB",
    headers: ["Rk.", "Player Name", "FPTS"],
  },
  {
    headerType: "This Week",
    position:"RB",
    headers: ["Rk.", "Player Name", "FPTS"],
  },
  {
    headerType: "This Week",
    position:"WR",
    headers: ["Rk.", "Player Name", "FPTS"],
  },
  {
    headerType: "This Week",
    position:"TE",
    headers: ["Rk.", "Player Name", "FPTS"],
  },
  {
    headerType: "ROS Rank",
    headers: ["Rk.", "Player Name", "Trade Value"],
    hasDropdown: true,
  },
  
  {
    headerType: "Season Leaders",
    position:"QB",
    headers: ["Rk.", "Player Name", "FPTS","Pass Attempts","Completions","Yards", "TDs", "INT", "Passer Rating", "Rushing Attempts"],
  },
  {
    headerType: "Season Leaders",
    position:"RB",
    headers: ["Rk.", "Player Name", "FPTS","Rushing Attempts","Rushing Yards","Rushing Yards per Carry", "Rushing TDs", "Targets", "Receptions", "Yards per Reception","Receiving TDs"],
  },
  {
    headerType: "Season Leaders",
    position:"WR",
    headers: ["Rk.", "Player Name", "FPTS","Receptions","Yards per Reception","Receiving TDs", "Rushing Attempts", "Rushing Yards", "Rushing Yards per Carry", "Rushing TDs"],

  },
  {
    headerType: "Season Leaders",
    position:"TE",
    headers: ["Rk.", "Player Name", "FPTS","Receptions","Yards per Reception","Receiving TDs", "Rushing Attempts", "Rushing Yards", "Rushing Yards per Carry", "Rushing TDs"],
 
  },
  {
    headerType: "Pre-Draft",
    headers: ["Rk.", "Player Name", "Consensus", "Bye", "Team" ],
    position:"All",
  },
  {
    headerType: "Pre-Draft",
    headers: ["Rk.", "Player Name", "Consensus", "Bye", "Team"],
    position:"QB",
  },
  {
    headerType: "Pre-Draft",
    headers: ["Rk.", "Player Name", "Consensus", "Bye", "Team" ],
    position:"RB",
  },
  {
    headerType: "Pre-Draft",
    headers: ["Rk.", "Player Name", "Consensus", "Bye", "Team" ],
    position:"WR",
  },
  {
    headerType: "Pre-Draft",
    headers: ["Rk.", "Player Name", "Consensus", "Bye", "Team" ],
    position:"TE",
  },
  {
    headerType: "2023 Season",
    position:"QB",
    headers: ["Rk.", "Player Name", "FPTS", "Pass Attempts", "Completions", "Passing Yards", "Passing TDs", "INT", "Passer Rating", "Rushing Attempts", "Rushing Yards", "Yards per Rush Attempt", "Rushing TDs"],
  },
  {
    headerType: "2023 Season",
    position:"RB",
    headers: ["Rk.", "Player Name", "FPTS", "Carries", "Rushing Yards", "Yards per Rush Attempt", "Rushing TDs", "Targets", "Receptions", "Yards per Reception", "Receiving TDs"],
  },
  {
    headerType: "2023 Season",
    position:"WR",
    headers: ["Rk.", "Player Name", "FPTS", "Receptions", "Yards per Reception", "Receiving TDs", "Carries", "Rushing Yards", "Yards per Rush Attempt", "Rushing TDs"],
  },
  {
    headerType: "2023 Season",
    position:"TE",
    headers: ["Rk.", "Player Name", "FPTS", "Receptions", "Yards per Reception", "Receiving TDs", "Carries", "Rushing Yards", "Yards per Rush Attempt", "Rushing TDs"],
  },
];

export const positions = [
  { id: 1, name: "All" },  
  { id: 2, name: "QB" },
  { id: 3, name: "RB" },
  { id: 4, name: "WR" },
  { id: 5, name: "TE" },
];

export const playerDummies = [
  {
    arr_img: Arrow_trending,
    roto_player_id: "joshalle1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/JoshAlle1.webp",
    Name: "J. Allen",
    POS: "QB",
    Team: "BUF",
    rosRank: "QB1",
  },
  {
    arr_img: Arrow_trending_down,
    roto_player_id: "justjeff1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/JustJeff1.webp",
    Name: "J. Jefferson",
    POS: "WR",
    Team: "MIN",
    rosRank: "WR4",
  },
  {
    arr_img: Arrow_trending,
    roto_player_id: "patrmaho1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/PatrMaho1.webp",
    Name: "P. Mahomes",
    POS: "QB",
    Team: "KC",
    rosRank: "QB3",
  },
  {
    arr_img: Arrow_trending_down,
    roto_player_id: "georkitt1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/GeorKitt1.webp",
    Name: "G. Kittle",
    POS: "TE",
    Team: "SF",
    rosRank: "TE4",
  },
];

export const categoriesFilters = [
  { id: 1, name: "Pre-Draft" },
  // { id: 2, name: "2023 Season" },
  // { id: 1, name: "This Week" },
  // { id: 2, name: "ROS Rank" },
  // { id: 3, name: "Season Leaders" },
];
