import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { formatPhoneNumber } from "react-phone-number-input";

interface PhoneNumberInputProps {
  value: string;
  onChange: (value: string) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  onChange,
}) => {
  const [formattedValue, setFormattedValue] = useState(value ? value : "");
  const handleChange = (newValue: any) => {
    setFormattedValue(newValue);
    onChange(newValue);
  };

  const inputStyle = {
    backgroundColor: "red",
  };

  return (
    <PhoneInput
      // number={formattedValue}
      international
      onChange={handleChange}
      defaultCountry="US"
      placeholder="Enter phone number"
      inputStyle={inputStyle}
      value={value}
    />
  );
};

export default PhoneNumberInput;
