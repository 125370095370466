import React from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import ResearchTable from "../ResearchTable/ResearchTable";

interface StatsItem {
  value: number;
  rank: string | null;
}

interface StatsData {
  [key: string]: StatsItem;
}

interface Props {
  statsData: StatsData | null;
  weeklyStats: any[] | null;
  playerPosition: string;
}

const ResearchStats: React.FC<Props> = ({
  statsData,
  weeklyStats,
  playerPosition,
}) => {
  if (!statsData && !weeklyStats) {
    return (
      <Paragraph
        text="-- No Data --"
        props={{ fontSize: "22px", fontWeight: 500 }}
        className="ml-5"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
    );
  }
  const getStatsCategoryByPosition = (position: string): string => {
    switch (position.toUpperCase()) {
      case "QB":
        return "passing";
      case "WR":
        return "receiving";
      case "TE":
        return "receiving";
      case "RB":
        return "rushing";
      default:
        return "receiving"; // Default to receiving if position is unknown
    }
  };
  const statsCategory = getStatsCategoryByPosition(playerPosition);

  const getTableHeaders = (category: string) => {
    switch (category) {
      case "passing":
        return [
          "WK",
          "OPP",
          "SCORE",
          "ATT",
          "CMP",
          "CMP%",
          "YDS",
          "YPA",
          "TD",
          "INT",
          "RTG",
        ];
      case "receiving":
        return ["WK", "OPP", "SCORE", "TAR", "REC", "YDS", "YPR", "TD"];
      case "rushing":
        return ["WK", "OPP", "SCORE", "ATT", "YDS", "AVG", "LNG", "TD"];
      default:
        return ["WK", "OPP", "SCORE", "TAR", "REC", "YDS", "YPR", "TD"];
    }
  };

  const tableHeaders = getTableHeaders(statsCategory);

  const tableData = [
    {
      wk: Math.floor(Math.random() * 50) + 1,
      opp: "LAR",
      fp: parseFloat((Math.random() * 50).toFixed(2)),
      rank: parseFloat((Math.random() * 50).toFixed(2)),
      tar: parseFloat((Math.random() * 50).toFixed(2)),
      rec: parseFloat((Math.random() * 50).toFixed(2)),
      yd: parseFloat((Math.random() * 50).toFixed(2)),
      ypt: parseFloat((Math.random() * 50).toFixed(2)),
      int: parseFloat((Math.random() * 50).toFixed(2)),
    },
    // Simulated table data continues...
  ];

  const getTitle = (title: string) => {
    switch (title) {
      case "totalFPS":
        return "Total FPS";
      case "avgFPPG":
        return "AVG. FPPG";
      case "receivingYards":
        return "Receiving Yards";
      case "receivingTDs":
        return "Receiving TDs";
      case "receptions":
        return "Receptions";
      case "passingYards":
        return "Passing Yards";
      case "passingTDs":
        return "Passing TDs";
      case "rushingYards":
        return "Rushing Yards";
      case "rushingTDs":
        return "Rushing TDs";
      default:
        return "";
    }
  };

  return (
    <div>
      {statsData && (
        <div className="flex items-center gap-[10px] overflow-x-auto no-scrollbar -mx-5 px-5">
          {Object.entries(statsData).map(([key, data], index) => (
            <div
              key={index}
              className="min-w-[112px] rounded-xl p-4 flex flex-col items-center justify-between"
              style={{
                backgroundColor: Colors.backgroundSecundary,
              }}
            >
              <Paragraph
                text={data.value.toString()}
                props={{
                  fontSize: "34px",
                  fontWeight: 500,
                  lineHeight: "85%",
                }}
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
              <div className="text-center">
                <Paragraph
                  text={data.rank ? data.rank : ""}
                  props={{
                    fontSize: "12px",
                    lineHeight: "130%",
                    marginTop: "20px",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <Paragraph
                  text={getTitle(key)}
                  props={{
                    fontSize: "10px",
                    lineHeight: "130%",
                    color: Colors.textLigth,
                    marginTop: data.rank === "" || !data.rank ? "38px" : "",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-5 mx-5">
        <Paragraph
          text={"2023"}
          props={{
            fontSize: "12px",
            fontWeight: 600,
            color: Colors.textLigth,
            marginBottom: "5px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <Paragraph
          text={"REGULAR SEASON"}
          props={{
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "100%",
            color: Colors.textLigth,
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
      </div>
      <div className="mt-4">
        <div className="mb-3 flex items-center">
          <Paragraph
            text={"FANTASY"}
            props={{
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "100%",
              marginLeft: "90px",
            }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <Paragraph
            text={statsCategory.toUpperCase()}
            props={{
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "100%",
              marginLeft: "33px",
            }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
        </div>
        <div className="overflow-x-hidden pb-5">
          <div className="overflow-y-auto">
            <ResearchTable
              tableHeader={tableHeaders}
              data={weeklyStats}
              statsCategory={statsCategory}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchStats;
