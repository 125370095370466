import React, { ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { Colors } from "../../Commons/Colors/Colors";

interface Props {
  tableHeader: string[];
  data: any[] | null;
  statsCategory: string;
}

const ResearchTable: React.FC<Props> = ({
  tableHeader,
  data,
  statsCategory,
}) => {
  const getColorForValue = (value: number) => {
    if (value >= 1 && value <= 15) {
      return "#F97881"; // Red
    } else if (value >= 16 && value <= 36) {
      return "#D9AEAE"; // Orange
    } else if (value >= 37 && value <= 50) {
      return "#BDF77F"; // Yellow
    } else {
      return "#76BC37"; // Green
    }
  };

  const getScoreColor = (teamScore: number, oppTeamScore: number) => {
    if (teamScore > oppTeamScore) {
      return "#76BC37"; // Green for win
    } else if (teamScore < oppTeamScore) {
      return "#F97881"; // Red for loss
    } else {
      return "#FFD700"; // Yellow for tie
    }
  };

  const renderCellContent = (weekData: any, key: string) => {
    switch (key) {
      case "WK":
        return weekData.week;
      case "OPP":
        return weekData.opponent;
      case "SCORE":
        return `${weekData.teamScore}-${weekData.oppTeamScore}`;
      default:
        return renderStatsByCategoryAndKey(weekData, statsCategory, key);
    }
  };

  const renderStatsByCategoryAndKey = (
    weekData: any,
    category: string,
    key: string,
  ) => {
    const stats = weekData[category];
    if (!stats) return "-";

    switch (category) {
      case "passing":
        return renderPassingStats(stats, key);
      case "receiving":
        return renderReceivingStats(stats, key);
      case "rushing":
        return renderRushingStats(stats, key);
      default:
        return "-";
    }
  };

  const renderPassingStats = (stats: any, key: string) => {
    switch (key) {
      case "ATT":
        return stats.Attempts || "-";
      case "CMP":
        return stats.Comp || "-";
      case "YDS":
        return stats.Yards || "-";
      case "TD":
        return stats.TDs || "-";
      case "INT":
        return stats.Int || "-";
      case "CMP%":
        return stats.CompPerc ? parseFloat(stats.CompPerc).toFixed(1) : "-";
      case "YPA":
        return stats.YardsPerAttempts
          ? parseFloat(stats.YardsPerAttempts).toFixed(1)
          : "-";
      case "RTG":
        return stats.QBRating ? parseFloat(stats.QBRating).toFixed(1) : "-";
      default:
        return "-";
    }
  };

  const renderReceivingStats = (stats: any, key: string) => {
    switch (key) {
      case "TAR":
        return stats.Targets || "-";
      case "REC":
        return stats.Recs || "-";
      case "YDS":
        return stats.Yards || "-";
      case "YPR":
        return stats.YardsPerReception || "-";
      case "TD":
        return stats.TDs || "-";
      default:
        return "-";
    }
  };

  const renderRushingStats = (stats: any, key: string) => {
    switch (key) {
      case "ATT":
        return stats.Attempts || "-";
      case "YDS":
        return stats.Yards || "-";
      case "AVG":
        return stats.YardsPerAttempts || "-";
      case "LNG":
        return stats.Long || "-";
      case "TD":
        return stats.TDs || "-";
      default:
        return "-";
    }
  };

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="overflow-x-auto">
      <div className="min-w-max">
        <Table className="w-full">
          <TableHeader>
            <TableRow className="hover:bg-transparent border-transparent">
              {tableHeader.map((item, index) => (
                <TableHead
                  key={index}
                  className={`text-white text-center text-[10px] h-5 py-1 px-1 ${
                    index === 0
                      ? "pl-4 pr-2"
                      : index === 1
                        ? "pr-4"
                        : index === 3
                          ? "pr-4"
                          : ""
                  }`}
                  style={{
                    backgroundColor: Colors.backgroundSecundary,
                  }}
                >
                  {item}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody className="">
            {data.map((weekData, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={`hover:bg-bg-[${Colors.backgroundSecundary}] border-transparent even:bg-[${Colors.backgroundSecundary}] text-white even:bg-[#FFFFFF1A] text-center`}
              >
                {tableHeader.map((key, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className={`text-[12px] h-5 py-1 px-1 whitespace-nowrap ${
                      cellIndex === 0
                        ? "pl-4 pr-2"
                        : cellIndex === 1
                          ? "pr-4"
                          : cellIndex === 2
                            ? "pr-4"
                            : ""
                    }`}
                    style={{
                      color:
                        key === "SCORE"
                          ? getScoreColor(
                              weekData.teamScore,
                              weekData.oppTeamScore,
                            )
                          : cellIndex > 2
                            ? getColorForValue(
                                parseInt(
                                  renderCellContent(weekData, key) as string,
                                ),
                              )
                            : "",
                      minWidth: key === "SCORE" ? "70px" : "50px", // Adjust these values as needed
                    }}
                  >
                    {renderCellContent(weekData, key)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ResearchTable;
