
export const teamColors: { [key: string]: string } = {
    "MIN": "#4F2683", // Vikings
    "BAL": "#A389FF", // Ravens
    "LAC": "#0080C6", // Chargers
    "KC": "#E31837", // Chiefs
    "LV": "#A5ACAF", // Raiders
    "PHI": "#004C54", // Eagles
    "NYG": "#1238C0", // Giants
    "BUF": "#0028B4", // Bills
    "MIA": "#08A391", // Dolphins
    "NE": "#C20A0A", // Patriots
    "SF": "#AA0000", // 49ers
    "WAS": "#773141", // Commanders
    "CHI": "#C83803", // Bears
    "DET": "#0076B6", // Lions
    "GB": "#203731", // Packers
    "ATL": "#A71930", // Falcons
    "CAR": "#0085CA", // Panthers
    "NO": "#D3BC8D", // Saints
    "TB": "#D50A0A", // Buccaneers
    "ARI": "#97233F", // Cardinals
    "LAR": "#003594", // Rams
    "SEA": "#002244", // Seahawks
    "DAL": "#041E42", // Cowboys
    "HOU": "#03202F", // Texans
    "IND": "#002C5F", // Colts
    "JAX": "#006778", // Jaguars
    "TEN": "#0C2340", // Titans
    "DEN": "#FB4F14", // Broncos
    "CLE": "#311D00", // Browns
    "PIT": "#FFB612", // Steelers
    "CIN": "#FB4F14", // Bengals
    "NYJ": "#006A37", // Jets
    "LA": "#4F2683"
  };
  