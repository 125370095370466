import React, { useState, useEffect } from "react";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router-dom";
import Backicon from "../../assets/imgs/arrow-back-left.svg";
import ChatBot from "../../assets/imgs/paywall-bot-img.svg";
import tickIcon from "../../assets/imgs/tick.svg";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { Button } from "../ui/button";
import { useApplePaymentAuth } from "../../Context/AppleContext/AppleContext";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { MONTLY_SUBSCRIPTION } from "../Subscription/PaymentMethod/constant";
import LottieAnimation from "../Commons/LottieAnimation/LottieAnimation";
import chatbotAnimation from "../../assets/animations/Chatbot.json";
import ChatbotPaywallLogo from "../../assets/imgs/logo-without-bot-icon.svg";
import SyncYourLeagueBg from "../../assets/imgs/sync-your-league-bg.png";
import SyncYourLeagueIcon from "../../assets/imgs/sync-your-league-icon.svg";
import SitStartBg from "../../assets/imgs/sit-start-bg.png";
import SitStartIcon from "../../assets/imgs/waiver-assistant.svg";
import WaiverWireIcon from "../../assets/imgs/waiver-wire-icon.svg";
import WaiverWireBg from "../../assets/imgs/waiver-wire-assitance.png";
import TradeAnalyzerIcon from "../../assets/imgs/trade-analyzer.svg";
import TradeAnalyzerBg from "../../assets/imgs/trade-analyzer-bg.png";
import LeagueAnalysisIcon from "../../assets/imgs/team-analyzer.svg";
import LeagueAnalysisBg from "../../assets/imgs/league-analysis-bg.png";
import Marquee from "react-fast-marquee";


const ChatbotPaywall = () => {
  const navigate = useNavigate();
  const { subscriptions, purchaseProduct } = useApplePaymentAuth();
  const { getUserProfile, getUserData } = useSupabaseAuth();

  const [price, setPrice] = React.useState("");
  const [currentUser, setCurrentUser] = React.useState<any>(null);
  const [isInitilized, setIsInitilized] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState<any>(null);
  const [selectedPlan, setSelectedPlan] = React.useState("Annual Plan"); // Track the selected plan

  useEffect(() => {
    const fethProfile = async () => {
      if (currentUser?.id) {
        const responde = await getUserProfile(currentUser?.id);
        setUserProfile(responde);
      }
    };

    if (currentUser?.id) {
      fethProfile();
    }
  }, [currentUser?.id]);

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  const fetchPrice = async () => {
    const item = subscriptions.find(
      (obj: { id: string }) => obj?.id === MONTLY_SUBSCRIPTION,
    );
    setPrice(item ? item.price : "$5.99");
  };

  useEffect(() => {
    if (!isInitilized) {
      fetchCurrentUser();
      fetchPrice();
      setIsInitilized(true);
    }
  }, [isInitilized]);

  const handleTryForFreeClick = async () => {
    purchaseProduct(userProfile, currentUser?.id, MONTLY_SUBSCRIPTION);
  };

  const fantasyFootballFeatures = [
    {
      label: "Sync your league",
      icon: SyncYourLeagueIcon,
      bgImg: SyncYourLeagueBg,
    },
    {
      label: "Answer Sit/Start Questions",
      icon: SitStartIcon,
      bgImg: SitStartBg,
    },
    {
      label: "Waiver Wire Assistance",
      icon: WaiverWireIcon,
      bgImg: WaiverWireBg,
    },
    {
      label: "Trade Analysis",
      icon: TradeAnalyzerIcon,
      bgImg: TradeAnalyzerBg,
    },
    {
      label: "League Analysis",
      icon: LeagueAnalysisIcon,
      bgImg: LeagueAnalysisBg,
    },
  ];

  return (
    <Layout>
      <div
        className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
        style={{ background: Colors.backgroundSecundary }}
        onClick={() => navigate("/home", { state: { fromLogin: true } })}
      >
        <img src={Backicon} alt="Back" />
      </div>

      <div className="pt-[4vh] flex flex-col justify-between h-[calc(100svh_-_40px)] -mt-20">
        <div
          className=" rounded-full w-[62.5px] h-[62.5px] mx-auto mb-4"
          onClick={() => navigate(`/chat/new`)}
        >
          <LottieAnimation animationData={chatbotAnimation} />
        </div>
        <div className="">
          <img
            src={ChatbotPaywallLogo}
            alt="ChatbotPaywallLogo"
            className="m-auto"
          />

          <Paragraph
            text={"Fantasy Football’s Next Big Thing"}
            ParagraphType="Pragragh22"
            color="textPrimary"
            className={
              "text-[15px] font-semibold text-center opacity-[0.75] mt-3 !leading-[100%]"
            }
          />
        </div>
        <div className="mt-[3svh] mb-5 -mx-5 px-5 flex gap-6 shrink-0">
        <Marquee>
          {fantasyFootballFeatures.map((item, index) => {
            return (
              <div
                key={index}
                className="w-[125px] h-[125px] rounded-xl flex flex-col justify-between p-2 shrink-0 mr-6"
                style={{
                  background: `url(${item.bgImg})`,
                }}
              >
                <img src={item.icon} alt="SyncYourLeagueIcon" className="w-6" />
                <Paragraph
                  text={item.label}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                  className={"text-[13px] font-semibold mt-3 !leading-normal"}
                />
              </div>
            );
          })}
          </Marquee>
        </div>

        <div className="flex flex-col gap-6 mb-5 mt-[2svh]">
          <div
            className={` rounded-[14px] relative cursor-pointer ${selectedPlan === "Annual Plan" ? "border-blue-500" : "border-[#0066FF33]"
              }`}
            onClick={() => setSelectedPlan("Annual Plan")}

          >
            <div
              className=" p-5 flex uyghbk items-center justify-between relative"
              style={{
                border:"4px solid",
                borderImage: 'linear-gradient(90deg, #05D883, #2861FD, #BB4CFF)',
                borderImageSlice:1,
                borderRadius: "14px",
              }}
            >
              <div
                className=" absolute -top-[1.3px] -left-[1.3px] px-4 py-3"
                style={{
                  background:
                    "linear-gradient(90deg, #05DA81 4.62%, #1A8ECE 100%)",
                  borderBottomRightRadius: "6px",
                  borderTopLeftRadius: "6px",
                }}
              >
                <Paragraph
                  text={"MOST POPULAR"}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                  className={"text-[12px] !font-bold !leading-normal"}
                />
              </div>
              <div className="mt-7">
                <Paragraph
                  text={"Annual Plan"}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                  className={"text-[20px] !font-semibold !leading-normal"}
                />
                <Paragraph
                  text={"First-access to all new features."}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                  className={"text-[10px] !font-semibold mt-3 !leading-normal"}
                />
              </div>
              <div className="">
                <Paragraph
                  text={"$5.99 / MO"}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                  className={"text-base !font-bold mt-3 !leading-normal"}
                />
              </div>
            </div>

            {selectedPlan === "Annual Plan" && (
              <div className="w-6 h-6 bg-[#00A3FF] rounded-full flex justify-center items-center absolute -top-1.5 -right-1.5">
                <img src={tickIcon} alt="tickIcon" />
              </div>
            )}
          </div>

          <div
            className={`rounded-[14px] p-5 border-[2px] cursor-pointer ${selectedPlan === "Monthly Plan" ? "border-blue-500" : "border-[#0066FF33]"
              } flex items-center justify-between relative`}
            onClick={() => setSelectedPlan("Monthly Plan")}
            style={{
              backgroundColor: Colors.backgroundGrayBluish,
            }}
          >
            <div className="">
              <Paragraph
                text={"Monthly Plan"}
                ParagraphType="Pragragh22"
                color="textPrimary"
                className={"text-[20px] !font-semibold !leading-normal"}
              />
              <Paragraph
                text={"Full access with monthly flexibility."}
                ParagraphType="Pragragh22"
                color="textPrimary"
                className={"text-[10px] !font-semibold mt-3 !leading-normal"}
              />
            </div>
            <div className="">
              <Paragraph
                text={"$7.99 / MO"}
                ParagraphType="Pragragh22"
                color="textPrimary"
                className={"text-base !font-bold mt-3 !leading-normal"}
              />
            </div>

            {selectedPlan === "Monthly Plan" && (
              <div className="w-6 h-6 bg-[#00A3FF] rounded-full flex justify-center items-center absolute -top-1.5 -right-1.5">
                <img src={tickIcon} alt="tickIcon" />
              </div>
            )}
          </div>
        </div>

        <Paragraph
          text={"Cancel anytime in the App Store"}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className={
            "text-[12px] !font-semibold opacity-[0.60] text-center mt-4"
          }
        />
        <Button
          className="rounded-[20px] py-4 text-[18px] mt-2"
          style={{
            background: "linear-gradient(to right, #3EE5FF, #0048FF)",
          }}
          onClick={handleTryForFreeClick}
        >
          TRY FOR FREE
        </Button>
      </div>
    </Layout>
  );
};

export default ChatbotPaywall;
