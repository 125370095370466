import React from "react";
import { Paragraph } from "../../Commons/Paragraph";
import production_icon from "../../../assets/imgs/production.svg";
import { Colors } from "../../Commons/Colors/Colors";

interface MetricPanelProps {
  heading: string;
  statCategories: any;
}

const MetricPanel: React.FC<MetricPanelProps> = ({
  heading,
  statCategories,
}) => {
  const productionMetricPanelBars: any = {
    Performance: {
      subHeading: "Performance",
      bgColor: "linear-gradient(90deg, #F58975 14%, #EB5743 60%)",
      categoryTitle: "Production",
    },
    Usage: {
      subHeading: "Usage",
      bgColor: "linear-gradient(90deg, #FFD089 0%, #D9594D 100%)",
    },
    Team: {
      subHeading: "Team",
      bgColor: "linear-gradient(90deg, #D9CDC6 0%, #CF9360 84.25%)",
    },
  };

  return (
    <div>
      <div className="flex items-center gap-[10px]">
        <img src={production_icon} alt="" />
        <Paragraph
          text={statCategories.categoryTitle}
          props={{
            fontSize: "24px",
            lineHeight: "145%",
            letterSpacing: "-0.48px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
      </div>

      <div className="mt-6 flex flex-col gap-5">
        {statCategories?.statTitles.map((item: any, index: any) => {
          console.log("*******************   ", heading);
          return (
            <ProgressBar
              value={
                statCategories?.percentileValues[index] <= 100
                  ? statCategories?.percentileValues[index]
                  : 100
              }
              label="text"
              key={index}
              bgColor={
                productionMetricPanelBars[heading]
                  ? productionMetricPanelBars[heading]?.bgColor
                  : "linear-gradient(90deg, #D9CDC6 0%, #CF9360 84.25%)"
              }
              subHeading={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MetricPanel;

interface ProgressBarProps {
  value: number;
  label: string;
  bgColor: string;
  subHeading: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  label,
  bgColor,
  subHeading,
}) => {
  return (
    <div className="flex my-2 mx-5 items-center">
      <div className="flex flex-row w-full items-center ">
        <Paragraph
          text={subHeading}
          props={{
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "145%",
            textAlign: "right",
            width: "125px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div
          className="overflow-hidden h-6 text-xs flex ml-4"
          style={{
            backgroundColor: Colors.backgroundSecundary,
            borderRadius: "24px",
            minWidth: "160px",
          }}
        >
          <div
            style={{ width: `${value}%`, background: bgColor }}
            className="shadow-none flex flex-col text-left pl-1 whitespace-nowrap text-white justify-center"
          >
            {value}%
          </div>
        </div>
      </div>
    </div>
  );
};
