import React, { useState, ChangeEvent } from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router";
import { Paragraph } from "../Commons/Paragraph";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import StyledInput from "./CardInput/CardInput";

interface CardDetails {
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  country: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

const AddCard: React.FC = () => {
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState<CardDetails>({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    country: '',
    address: '',
    city: '',
    state: '',
    zipCode: ''
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCardDetails({
      ...cardDetails,
      [name]: value
    });
  };

  const navigateBack = () => {
    navigate("/subscription");
  };
    

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
          style={{ background: Colors.backgroundSecundary }}
          onClick={navigateBack}
        >
          <img src={ArrowBackIcon} alt="Back" />
        </div>
        <Paragraph
          text="Add card"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>

      <div className="mt-6">
        <Paragraph
          text="Your card details are securely encrypted and stored"
          props={{ fontSize: "14px", color: Colors.textLigth, lineHeight: "16px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />

        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[18px] py-6 mt-4 mb-10"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          <StyledInput
            placeholder="Card Number"
            onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'cardNumber' } })}
          />

          <div className="flex gap-4 mt-7">
            <StyledInput
              placeholder="MM/YY"
              onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'expiryDate' } })}
            />
            <StyledInput
              placeholder="CVV"
              onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'cvv' } })}
            />
          </div>
        </div>

        <Paragraph
          text="Billing address"
          props={{ fontSize: "18px", fontWeight: 500 }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />

        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[18px] py-6 mt-5"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          <div className="flex flex-col gap-[10px]">
            <StyledInput
              placeholder="Country"
              onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'country' } })}
            />
            <StyledInput
              placeholder="Address"
              onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'address' } })}
            />
            <StyledInput
              placeholder="City"
              onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'city' } })}
            />
          </div>

          <div className="flex gap-4 mt-7">
            <StyledInput
              placeholder="State"
              onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'state' } })}
            />
            <StyledInput
              placeholder="Zip Code"
              onChange={(e) => handleInputChange({ ...e, target: { ...e.target, name: 'zipCode' } })}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddCard;
