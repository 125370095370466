import React, { useState } from "react";
import { Button } from "../../ui/button";
import { Colors } from "../Colors/Colors";

interface TabItem {
  id: number;
  name: string;
}

interface TableTabsProps {
  tabItems: TabItem[];
  onTabSelect: (selectedTab: TabItem) => void;
  tabCustomStyle?: string;
  selectedTabStyle?: string;
  unselectedTabStyle?: string;
  renderTabText?: (item: TabItem) => string;
}

const TableTabs: React.FC<TableTabsProps> = ({
  tabItems,
  onTabSelect,
  tabCustomStyle,
  selectedTabStyle,
  unselectedTabStyle,
  renderTabText = (item) => item.name,
}) => {
  const [selectedTab, setSelectedTab] = useState<TabItem | null>({
    id: 1,
    name: "This Week",
  });

  const handleTabClick = (tab: TabItem) => {
    setSelectedTab(tab);
    onTabSelect(tab);
  };

  return (
    <div className="flex gap-2">
      {tabItems.map((tab) => (
        <div
          key={tab.id}
          onClick={() => handleTabClick(tab)}
          className={`rounded-[50px] p-5 flex justify-center items-center text-white cursor-pointer ${
            selectedTab && selectedTab.id === tab.id
              ? `bg-[${Colors.backgroundTertiary}] ${selectedTabStyle}`
              : `bg-[${Colors.backgroundSecundary}] ${unselectedTabStyle}`
          } ${tabCustomStyle}`}
        >
          {renderTabText(tab)}
        </div>
      ))}
    </div>
  );
};

export default TableTabs;
