import React from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";

type Chat = {
  created_at: string;
  id: number;
  detail: string | null;
  user_email: string;
  title: string;
};

const ChatItem: React.FC<{ chat: Chat }> = ({ chat }) => {
  const object = {
    selected: false,
  };
  return (
    <li
      className="p-2.5"
      key={chat.id}
      style={{
        background: object.selected ? Colors.backgroundSecundary : "",
        borderRadius: object.selected ? "6px" : "",
        border: object.selected ? "1px solid rgba(255, 255, 255, 0.15)" : "",
      }}
    >
      <a href={"/chat/"+chat.id}>
        <Paragraph
          text={chat.title}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-[10px] md:text-xs xl:text-[13px] opacity-50"
        />
      </a>
    </li>
  );
};

const ChatGroup: React.FC<{ groupTitle: string; chats: any[] }> = ({
  groupTitle,
  chats,
}) => {
  return (
    <div id="today-chats" className="mt-5 mx-5">
      <Paragraph
        text={groupTitle}
        props={{
          fontSize: "18px",
          marginLeft: "10px",
          marginTop: "20px",
          fontWeight: 600,
        }}
        ParagraphType="PragraghSemiBold400Normal"
        color="textPrimary"
      />
      <div className="mt-2.5">
        <ul className="space-y-1">
          {chats.map((chat) => (
            <ChatItem key={chat.id} chat={chat} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const MenuSiderItem = ({ chatHistories }: { chatHistories: any }) => {
  return (
    <div>
      {Object.entries(chatHistories).map(([groupTitle, chatList]) => {
        const chats = chatList as Chat[];
        if (chats.length > 0) {
          return (
            <ChatGroup
              key={groupTitle}
              groupTitle={groupTitle}
              chats={chats}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
export default MenuSiderItem;
