import { Colors } from "../Colors/Colors";
import { Paragraph } from "../Paragraph";
import arrowRight from "../../../assets/imgs/arrow-right.svg";

interface SettingItemProps {
  icon?: string;
  text?: string;
  label: string;
  action?: () => void;
  arrow?: boolean;
  lastItem?: boolean;
  customStyle?: string
}

const SettingItem: React.FC<SettingItemProps> = ({
  icon,
  text,
  action,
  label,
  arrow = true,
  lastItem,
  customStyle="py-5"
}) => (
  <div
    className={customStyle}
    style={{
      borderBottom: !lastItem ? `1px solid ${Colors.backgroundSecundary}` : "",
    }}
    onClick={action}
  >
    <div className="flex items-center h-10">
      <img src={icon} alt="" className="mr-2" />
      <Paragraph
        text={label}
        props={{
          fontSize: "15px",
          fontWeight: 600,
          letterSpacing: "-0.5px",
        }}
        ParagraphType="Paragraph22"
        color="textPrimary"
      />
      <div className="ml-auto">
        {text ? (
          <Paragraph
            text={text}
            props={{
              fontSize: "15px",
              fontWeight: 600,
              letterSpacing: "-0.5px",
              color: Colors.textLigth,
            }}
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        ) : (
          arrow && <img src={arrowRight} alt="" className="mr-2" />
        )}
      </div>
    </div>
  </div>
);

export default SettingItem;
