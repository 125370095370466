import React from "react";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import { abbreviateName } from "../../../lib/utils";
import { useNavigate } from "react-router";

interface PlayersCardProps {
  arrowImage: string;
  playerImage?: string;
  playerName: string;
  playerPosition: string;
  rosRank: string;
  opponent: string;
  id:string
}

const PlayersCard: React.FC<PlayersCardProps> = ({
  arrowImage,
  playerImage,
  playerName,
  playerPosition,
  rosRank,
  opponent,
  id
}) => {
  const navigate = useNavigate()
  return (
    <div
      className={`w-[279px] h-[118px] pr-1 rounded-2xl relative`}
      style={{
        backgroundColor: Colors.backgroundSecundary,
        backgroundPosition: "bottom right",
      }}
    >
      <div className="flex justify-between pr-2">
        <div className="ml-5 pt-1">
          <div className="flex items-center gap-1">
            <img
              src={arrowImage}
              alt="Arrow_trending"
              className=" w-[18px] h-[18px]"
            />
            <Paragraph
              text="14"
              props={{ fontSize: "16px", fontWeight: "700" }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
          <div>
            <Paragraph
              text={ playerName}
              props={{ fontSize: "20px", fontWeight: "500" }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
            <Paragraph
              text={`${playerPosition} • ${opponent}`}
              props={{
                fontSize: "12px",
                fontWeight: "800",
              }}
              ParagraphType="Pragragh22"
              color="backgroundGrayPrimary"
            />

            <Paragraph
              text={`ROS Rank: ${rosRank}`}
              props={{ fontSize: "10px", fontWeight: "500", marginTop: "16px" }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
        </div>
        <div className=" absolute bottom-0 right-3 cursor-pointer"
              onClick={()=> navigate(`/player-profile/${id}`)}>
          {playerImage && (
            <img
              src={playerImage}
              alt="player_icon"
              className="w-[144px] h-[108px]"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayersCard;
