import React, { useEffect, useState } from "react";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import User_details_img from "../../assets/imgs/LoginBannerImage.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import UserDetailsCard from "./UserDetailsCard/UserDetailsCard";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { convertDateFormat, validateEmail } from "../../lib/utils";
import warning_icon from "../../assets/imgs/warning-icon.svg";

export const UserDetails: React.FC = () => {
  const navigate = useNavigate();
  const { useAuthStore, getProfileForDetails, signupProfile } =
    useSupabaseAuth();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [showError, setShowError] = useState(false);
  const user = useAuthStore((state: any): any => state.user);
  const appleProfile = useAuthStore((state: any): any => state.appleProfile);
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user_id = user?.user?.id ? user?.user?.id : user?.id;

      if (user_id) {
        if (user?.user?.app_metadata?.provider === "apple" && appleProfile) {
          setEmail(user?.user?.email || appleProfile.emailApple || "");
          setName(`${appleProfile?.givenName} ${appleProfile?.familyName}`);
        }

        if (user?.user?.app_metadata?.provider === "google") {
          setEmail(user?.user?.email || "");
          setName(user?.user?.user_metadata?.full_name || "");
        }

        const profile = await getProfileForDetails(user_id);

        if (profile) {
          if (profile.user_email) {
            setEmail(profile.user_email || "");
          }

          if (profile.user_name) {
            setName(profile.user_name || "");
          }

          if (profile.referral_code) {
            setReferralCode(profile.referral_code || "");
          }
        }
      }
    };

    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const handleChange = (field: string, value: string) => {
    if (field === "email") {
      setEmail(value);
    } else if (field === "name") {
      setName(value);
    } else if (field === "referral code") {
      setReferralCode(value);
    }
  };

  const handleSubmit = async () => {
    const user_id = user?.user?.id ? user?.user?.id : user?.id;

    if (user && user_id && !loading) {
      if (name.length === 0 || !validateEmail(email)) {
        setShowError(true);
        return;
      }
      setLoading(true);
      try {
        const response = await signupProfile(
          user_id as string,
          name,
          email,
          referralCode,
        );

        if (response?.sucess) {
          useAuthStore.getState().clearAppleProfile();
          navigate("/home");
        } else {
          setServerError(true);
        }
      } catch (error) {
        setLoading(false);
        setServerError(true);
      }

      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="flex items-center justify-between relative z-20">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
          style={{
            background: Colors.backgroundSecundary,
          }}
          onClick={() => navigate("/welcome")}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="User Details"
          props={{ fontSize: "22px", marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>
      <div className=" flex h-[calc(90svh_-_80px)] flex-col justify-between items-center sm:flex-row">
        <div className="w-full sm:w-1/2">
          <div className="flex flex-col text-center justify-center items-center">
            <div className="relative mt-8">
              <img
                src={User_details_img}
                alt=""
                className="w-[153.907px] absolute top-[00px] sm:-top-[40px] left-[50%] -translate-x-[50%] z-10 "
              />
              <div className="h-[20vh] w-[173.907px] chatbot-bot-bg"></div>
            </div>
            <div className="sm:text-5xl text-3xl text-white font-bold mt-10">
              Let’s get to know you
            </div>
            <div
              className="mt-3 text-xl sm:text-2xl"
              style={{
                color: Colors.textLigth,
              }}
            >
              We need a few details to set up your profile and get you started.
            </div>
          </div>
        </div>
        <div className="sm:w-1/3 sm:mt-0 flex flex-col gap-5 justify-center text-center w-full mt-5">
          <UserDetailsCard
            email={email}
            name={name}
            referralCode={referralCode}
            showError={showError}
            onChange={handleChange}
          />
          {serverError && (
            <div className="flex w-full justify-center">
              <Paragraph
                text={"Invalid server error"}
                props={{
                  fontSize: "15px",
                  fontWeight: 600,
                  color: Colors.textRed,
                }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
              <img src={warning_icon} alt="" className=" ml-3" />
            </div>
          )}

          <Button
            className="w-full h-[60px] text-base my-4"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>
    </Layout>
  );
};
