import { useNavigate } from "react-router-dom";
import BrandLogo from "../Commons/BrandLogo/BrandLogo";
import { Layout } from "../Layouts/Layout";
export const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="flex flex-col items-center py-[24px] w-full h-full justify-around">
        <div className="flex flex-col items-center">
          <div className="text-6xl text-white font-semibold text-center">
            RotoBot <br /> AI
          </div>
          <button
            className="bg-[#0048FF] w-40 p-3 rounded-[50px] text-white m-auto mt-10"
            onClick={() => navigate("/on-boarding")}
          >
            Continue
          </button>
        </div>
        <div className="mt-7">
          <BrandLogo />
        </div>
      </div>
    </Layout>
  );
};
