import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import pop_up_bg from "../../assets/imgs/popUp-bg.svg";
import { Paragraph } from "../Commons/Paragraph";
import { Button } from "../ui/button";

interface PopUpProps {
  isVisible: boolean;
  onClose: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ isVisible, onClose }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-30"
          onClick={onClose}
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
          }}
        >
          <motion.div
            className="text-white flex justify-center items-end h-full p-[14px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div
              style={{
                borderRadius: "20px",
                background: "#212130",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              className="mb-12"
            >
              <img
                src={pop_up_bg}
                alt="Popup Background"
                className="rounded-t-[20px] w-full"
              />
              <div className="mt-5 text-center p-2">
                <Paragraph
                  text="Improve your experience"
                  props={{
                    fontSize: "23px",
                    fontWeight: "600",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                />
                <Paragraph
                  text="Share your thoughts and schedule a call with us to provide feedback or participate in a usability test. Your insights are invaluable in making our app better for you and everyone else."
                  props={{
                    fontSize: "14px",
                    fontWeight: "400",
                    opacity: "0.50",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                />
                <Button className="bg-[#874FFF] hover:bg-[#874FFF] rounded-[10px] mt-4 w-[90%]">
                  <a
                    href="https://calendar.app.google/sQ1q7CGXQ7sceVsJ6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Schedule a call
                  </a>
                </Button>
                <Button
                  className="bg-transparent hover:bg-transparent rounded-[10px]"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PopUp;
