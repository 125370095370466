import React, { useState } from "react";
import { Paragraph } from "../Paragraph";
import SelectedStar from "../../../assets/imgs/selected.svg"
import unSelectedStar from "../../../assets/imgs/unselected.svg"

// Custom star icons
const selectedStar = <span style={{ color: "gold" }}>★</span>;
const unselectedStar = <span style={{ color: "gray" }}>☆</span>;

interface RatingStarProps {
  label: string;
  onChange: (value: number) => void;
  value: number;
}

const RatingStar: React.FC<RatingStarProps> = ({ label, onChange, value }) => {

  const handleStarClick = (index: number) => {
    onChange(index + 1);
  };

  return (
    <div className="flex items-center justify-between w-full">
      <Paragraph
        text={label}
        props={{ fontSize: "15px", fontWeight:"500"}}
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <div className="flex items-center gap-2 cursor-pointer">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            onClick={() => handleStarClick(index)}
            className="cursor-pointer"
          >
            {index < value ? <img src={SelectedStar} alt=""/> : <img src={unSelectedStar} alt=""/>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingStar