import React, { useState } from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";

interface PlayerDetailsProps {
  name: string;
  position: string;
  posRank: string;
  ovrRank: string;
  status?: string;
}

const PlayerDetails: React.FC<PlayerDetailsProps> = ({
  name,
  position,
  posRank,
  ovrRank,
  status,
}) => {

  return (
    <div className="flex-1">
      <Paragraph
        text={name.split(" ")[0]}
        className="text-2xl md:text-3xl lg:text-4xl !font-medium leading-[100%] hidden sm:block opacity-50"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <Paragraph
        text={name.split(" ")[1]}
        className="text-4xl sm:text-5xl md:text-6xl lg:text-[77.273px] !font-bold leading-[100%] hidden sm:block mb-6"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <Paragraph
        text={name}
        className="text-4xl !font-bold !leading-[110%] sm:hidden"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <Paragraph
        text={position}
        ParagraphType="Pragragh22"
        className="text-base sm:text-2xl md:text-3xl lg:text-[34.091px] !font-bold leading-[100%]"
        color="textPrimary"
      />
      <Paragraph
        text={`Status:  ${status || "--"} `}
        className="text-base sm:text-[18px] lg:text-[22px] !font-semibold leading-[100%] opacity-80 mt-5 sm:block hidden"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <div
        className={`p-[10px] rounded-xl inline-flex gap-4 items-center mt-6 sm:hidden`}
        style={{
          backgroundColor: Colors.backgroundSecundary,
          backdropFilter: "blur(5px)",
          willChange: "backdrop-filter",
          transform: "translateZ(0)",
        }}
      >
        <div className="text-center">
            <Paragraph
              text={posRank || "--"}
              className="text-[28px] md:text-3xl lg:text-[34.091px] !font-bold leading-[100%]"
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          <Paragraph
            text="Pos. Rank"
            className="text-[10px] md:text-xs lg:text-sm opacity-70"
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
        </div>
        <div
          className="h-[35.5px] w-[1px]"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        ></div>
        <div className="text-center">
          <Paragraph
            text={ovrRank || "--"}
            className="text-[28px] md:text-3xl lg:text-[34.091px] !font-bold leading-[100%]"
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <Paragraph
            text="Ovr. Rank"
            className="text-[10px] md:text-xs lg:text-sm opacity-70"
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerDetails;
