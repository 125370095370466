import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from "@capacitor/app";

interface URLOpenListenerEvent {
  url: string;
}

const AppUrlListener: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.app').pop();
      if (slug) {
        navigate(slug);
      }
    });

    return () => {
      App.removeAllListeners();
    };
  }, [navigate]);

  return null;
};

export default AppUrlListener;
