import { useCallback, useEffect, useRef, useState, ReactNode } from "react";
import arrow from "../../../assets/imgs/arrow-back-left.svg";
import { Colors } from "../../Commons/Colors/Colors";

interface ExpandableCardProps {
  header: ReactNode;
  children: ReactNode;
}

export function FAQsExpandableCard({ header, children }: ExpandableCardProps) {
  const [expanded, setExpanded] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const calculateHeight = useCallback(() => {
    if (headerRef.current && contentRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      const contentHeight = expanded ? contentRef.current.scrollHeight : 0;
      setHeaderHeight(headerHeight);
      setContentHeight(contentHeight);
    }
  }, [expanded]);

  useEffect(() => {
    calculateHeight();
  }, [calculateHeight]);

  useEffect(() => {
    calculateHeight();
  }, [expanded, calculateHeight]);

  return (
    <div
      className="inline-block max-w-sm mx-auto rounded-xl overflow-hidden transition-all duration-500 ease-in-out p-4 relative w-full"
    >
        
      <div onClick={toggleExpanded} ref={headerRef}>
        <div className="flex justify-between items-center">

        {header}

        <img
        src={arrow}
        alt=""
        className={`${expanded ? "rotate-[90deg]" : "rotate-[-90deg]"} transition-all duration-300 ease-in-out`}
      />
        </div>
      </div>

      <div
        ref={contentRef}
        className="transition-all duration-300 ease-in-out"
        style={{
          height: expanded ? contentHeight : "0px",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
      
    </div>
  );
}
