import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

interface TabItem {
  label: string;
  content: React.ReactNode;
}

interface GenericTabsProps {
  tabs: TabItem[];
  onTabChange?: (selectedIndex: number) => void;
}

const TabPanel: React.FC<{
  value: number;
  index: number;
  children: React.ReactNode;
}> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      className="mt-8"
    >
      {value === index && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.8 }}
          className="text-white"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
};

const GenericTabsComponent: React.FC<GenericTabsProps> = ({
  tabs,
  onTabChange,
}) => {
  const [value, setValue] = useState(0);
  const [underlineStyle, setUnderlineStyle] = useState({ left: 0, width: 50 });  

  const handleChange = (index: number, tabRef: HTMLDivElement | null) => {
    setValue(index);
    if (onTabChange) {
      onTabChange(index);
    }
    if (tabRef) {
      setUnderlineStyle({
        left: tabRef.offsetLeft,
        width: tabRef.offsetWidth,
      });
    }
  };

  useEffect(() => {
    if (onTabChange) {
      onTabChange(value);
    }
  }, [value, onTabChange]);  

  useEffect(()=>{

    if(window.innerWidth > 768){
      setUnderlineStyle({
        left: 20,
        width: 50,
      });
    }
    if(window.innerWidth > 1024){
      setUnderlineStyle({
        left: 30,
        width: 50,
      });
    }
  },[])

  return (
    <div className="w-full">
      <div className="relative flex justify-between rounded-lg w-[90%] m-auto">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`cursor-pointer py-2 text-white text-base sm:text-xl md:text-2xl lg:text-3xl font-medium`}
            onClick={(e) => handleChange(index, e.currentTarget)}
            id={`custom-tab-${index}`}
            aria-controls={`custom-tabpanel-${index}`}
          >
            {tab.label}
          </div>
        ))}
        <motion.div
          className="flex justify-center absolute bottom-0 sm:-bottom-3"
          animate={underlineStyle}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <motion.div className="h-1 md:h-2 min-w-[75px] md:min-w-[120px] lg:min-w-[156.25px] m-auto bg-[#3EC4FF] rounded-lg" />
        </motion.div>
      </div>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};

export default GenericTabsComponent;
