import React from "react";
import { Layout } from "../Layouts/Layout";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import { useNavigate } from "react-router-dom";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";

// icons
import CharPersonIcon from "../../assets/imgs/player-analyzer.svg";
import DataBaseIcon from "../../assets/imgs/team-analyzer.svg";
import PeopleComunityIcon from "../../assets/imgs/task-list-square-person.svg";
import PersonQuestionIcon from "../../assets/imgs/waiver-assistant.svg";
import VectorIcon from "../../assets/imgs/league-selector.svg";
import TradeAnalyzer from "../../assets/imgs/trade-analyzer.svg";
import DraftAnalyzer from "../../assets/imgs/draft-analyzer.svg";

const capabilities = [
  {
    icon: DraftAnalyzer,
    title: "Draft Analyzer",
    description:
      "Users can choose the league they want to focus on in their discussion with the chatbot.",
  },
  {
    icon: PeopleComunityIcon,
    title: "Sit/Start Decider",
    description:
      "Users can choose the league they want to focus on in their discussion with the chatbot.",
  },
  {
    icon: DataBaseIcon,
    title: "Team Analyzer",
    description:
      "Users can choose the league they want to focus on in their discussion with the chatbot.",
  },
  {
    icon: CharPersonIcon,
    title: "Player Analyzer",
    description:
      "Users can choose the league they want to focus on in their discussion with the chatbot.",
  },
  {
    icon: PersonQuestionIcon,
    title: "Waiver Assistant",
    description:
      "Users can choose the league they want to focus on in their discussion with the chatbot.",
  },
  {
    icon: TradeAnalyzer,
    title: "Trade Analyzer",
    description:
      "Users can choose the league they want to focus on in their discussion with the chatbot.",
  },
];

const Capabilities = () => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/chat/new");
  };

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
          style={{ background: Colors.backgroundSecundary }}
          onClick={navigateBack}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="Capabilities"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>

      <div className="grid grid-cols-2 mt-10 gap-5">
        {capabilities.map((capability, index) => (
          <div
            key={index}
            className=" rounded-2xl px-2 py-3 max-w-[171px] min-h-[180px] flex items-center"
            style={{
              background: Colors.backgroundSecundary,
            }}
          >
            <div>
              <img
                src={capability.icon}
                alt={capability.title}
                className="w-6"
              />
              <div className="mt-2 mb-4">
                <Paragraph
                  text={capability.title}
                  props={{ fontSize: "15.39px", fontWeight: 600 }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
              </div>
              <Paragraph
                text={capability.description}
                props={{ fontSize: "8.55px", fontWeight: 400 }}
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Capabilities;
