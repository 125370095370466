import React from "react";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import Null_player from "../../../assets/imgs/null-player-img.svg";
import { teamColors } from "../../../config/constants/teamColors";
import arrowRight from "../../../assets/imgs/arrow-right.svg";

type PlayersCardProps = {
  playerSmallIcon: string;
  playerName: string;
  playerTeam: string;
  bgColor: string;
};

const PlayersCard: React.FC<PlayersCardProps> = ({
  playerSmallIcon,
  playerName,
  playerTeam,
  bgColor,
}) => {
  const formatPlayerStatus = (status: string) => {
    //if status is undefined or null return empty string
    if (status === undefined) return "";
    if (status === null) return "";
    return status.replace(/!/g, "").trim();
  };

  return (
    <div
      className="p-3 md:pb-4 flex items-center rounded-2xl relative w-[82%] max-w-[500px]"
      style={{
        background: Colors.backgroundSecundary,
      }}
    >
      <div className="relative w-[60.52px] h-[47.84px] xl:h-[57.84px] mt-[2px]">
        <div
          className="rounded-xl w-[46.08px] md:w-[58px] xl:w-[64px] 2xl:w-[71px] h-[46.08px] md:h-[58px] xl:h-[64px] 2xl:h-[71px] flex items-end overflow-hidden"
          style={{
            backgroundColor: bgColor ? teamColors[bgColor] : "#4F2683",
          }}
        >
          <img
            className={playerSmallIcon ? " scale-[1.7]" : "h-[45px]"}
            src={playerSmallIcon || Null_player}
            alt=""
          />
        </div>
      </div>
      <div className="flex-1 md:pl-5">
        <Paragraph
          text={playerName}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-base md:text-[20.527px] xl:text-[22.527px] 2xl:text-[29.527px] !leading-[100%] font-medium"
        />
        <Paragraph
          text={formatPlayerStatus(playerTeam)}
          props={{
            opacity: 0.7,
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-[12px] xl:text-[12px] 2xl:text-[13.5px] mt-2 !leading-[100%]"
        />
      </div>
      <div
        className="ml-auto rounded-[100px] w-[30px] h-[30px] flex justify-center items-center"
        style={{
          background: Colors.backgroundSecundary,
        }}
      >
        <img src={arrowRight} alt="" className="w-4" />
      </div>
    </div>
  );
};

export default PlayersCard;
