import React from "react";
import productionIcon from "../../../assets/imgs/production.svg";

interface StatItemProps {
  label: string;
  value: string | number;
  leagueAvg: string | number;
  rank: string;
  percentile: string | number;
}

interface StatsSectionProps {
  categoryTitle: string;
  statTitles: string[];
  statValues: string[];
  percentileValues?: string[];
  avgValues?: string[];
  rankValues?: string[];
}

const StatItem: React.FC<StatItemProps> = ({
  label,
  value,
  leagueAvg,
  rank,
  percentile,
}) => {
  const percentage = Number(percentile) || 0;

  const formatNumber = (value: string | number): string => {
    if (typeof value === "number") {
      return value.toFixed(0);
    }

    if (value.endsWith("%")) {
      return value;
    }

    const numValue = parseFloat(value);
    return !isNaN(numValue) ? numValue.toFixed(1) : value;
  };

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center text-[10px] md:text-xs xl:text-sm mb-1 text-[#9D9D9D]">
        <span>{label}</span>
        <span>{rank}</span>
      </div>
      <div
        className="relative h-[43.567px] rounded-2xl"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <div className="absolute top-[-20px] left-[50%] -translate-x-[10%] text-[10px] md:text-xs text-[#9A9A9A]">
          {leagueAvg} (LEAGUE AVG)
        </div>
        <div className="absolute -top-[5px] left-[50%] -translate-x-[40%] rounded-[15px] opacity-20 bg-[#DADADA] w-[3px] h-[52px] z-10"></div>
        <div
          className="absolute top-0 left-0 h-full rounded-[8px] flex items-center"
          style={{
            width: `${percentage}%`,
            background: "linear-gradient(90deg, #012B99, #0148FE)",
          }}
        >
          <span className="text-xs font-semibold ml-[10px]">
            {formatNumber(value)}
          </span>
        </div>
      </div>
    </div>
  );
};


const StatsSection: React.FC<StatsSectionProps> = ({
  categoryTitle,
  statTitles,
  statValues,
  percentileValues,
  avgValues,
  rankValues,
}) => {
  return (
    <div className="mb-6">
      <div className="flex items-center text-white mb-4 sm:mb-5 md:mb-6 lg:mb-7">
        <img src={productionIcon} alt="productionIcon" className="w-6" />
        <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-[45.393px] font-semibold ml-2">{categoryTitle}</h3>
      </div>
      {statTitles.map((title, index) => (
        <StatItem
          key={index}
          label={title}
          value={statValues[index] || "N/A"}
          leagueAvg={avgValues ? avgValues[index] : "10"}
          rank={rankValues ? rankValues[index] : "17"}
          percentile={(percentileValues && percentileValues[index]) || "0"}
        />
      ))}
    </div>
  );
};

export default StatsSection;
