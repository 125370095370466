import { useState, useEffect, useRef } from "react";

import { TYPECHAT } from "../../config/constants/constants";

import { Paragraph } from "../Commons/Paragraph";

//color paletee
import { Colors } from "../Commons/Colors/Colors";

import CapabilitiesIcon from "../../assets/imgs/new-chat-suggestion-icon.svg";

import sendChatIcon from "../../assets/imgs/send-chat.svg";
import MicrophoneIcon from "../../assets/imgs/microphone-2.svg";
import RecordingIcon from "../../assets/imgs/recording.svg";

//components
import SuggestionCarrousel from "./SuggestionCarrousel";
import { EmptyChat } from "../Chat/EmptyChat";
import { Messages } from "../Chat/Messages";
import { LandingScreenMenu } from "./LandingScreenMenu";

import { useNavigate, useParams } from "react-router-dom";

import {
  ChatRotoBot,
  ChatRotoBotSuggestions,
  fetchDetailChat,
} from "../../Controllers/ChatRotobot";
import { markFeedBack } from "../../Controllers/Feedback";

//Type
import { ChatItem } from "./type";
import { useSafeAreaInsets } from "../../Context/SafeAreaContext/SafeAreaContext";
import useSpeechRecognition from "../../Services/speechRecognitionService";

import { Device } from "@capacitor/device";

import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import CapabilitiesModal from "../CapabilitiesModal/CapabilitiesModal";
import { Capacitor } from "@capacitor/core";
import useUserProfile from "../../hooks/useUserProfile";
import usePaywall from "../../hooks/useChatbotPayWall";
import { PlayerProfileById } from "../../Controllers/PlayerProfileById";
import PlayersCard from "./PlayersCard/PlayersCard";
import ScrollToBottom from "react-scroll-to-bottom";
import SkeletonLoader from "../Commons/LoadingSkeleton/LoadingSkeleton";

interface QuestionItem {
  id: number;
  title: string;
}

export const Chatbot: React.FC = () => {
  const {
    transcript,
    isListening,
    startListening,
    stopListening,
    setTranscript,
  } = useSpeechRecognition();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { bottom } = useSafeAreaInsets();
  const [isFocused, setIsFocused] = useState(false);
  const [question, setQuestion] = useState<any | string>(null);
  const [chats, setChats] = useState<ChatItem[]>([]);
  const [activeQuestion, setActiveQuestion] = useState<boolean>(false);
  const [player, setPlayer] = useState<any>();
  const navigate = useNavigate();
  const [landingScreenMenu, setLandingScreenMenu] = useState(false);
  const [inputHeight, setInputHeight] = useState("56px");
  const isLoaded = useRef(false);
  const { firstName, fullName } = useUserProfile();
  const [isAnimation, setIsAnimation] = useState<null | boolean>(null)
  usePaywall();
  const [dataItems, setDataItems] = useState<QuestionItem[]>([
    {
      id: 0,
      title: "Should I start Puka Nacua or Deebo this week in half-PPR?",
    },
    { id: 1, title: "How will Dak Prescott do this season?" },
    { id: 2, title: "Give me Garrett Wilson's weekly outlook?" },
    { id: 3, title: "What's the season recap for Nico Collins?" },
    {
      id: 4,
      title:
        "Who are some mid round sleepers that have breakout potential in 2024?",
    },
  ]);
  const [platform, setPlatform] = useState<string>("");
  const { id, playerId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isActivateAnimation, setIsActivateAnimation] = useState(false);
  const { getUserData } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const baseStyle = {
    width: "100%",
    height: inputHeight,
    padding: "16px",
    background: Colors.backgroundSecundary,
    borderTopLeftRadius: inputHeight !== "56px" ? "20px" : "50px",
    borderBottomLeftRadius: inputHeight !== "56px" ? "20px" : "50px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    color: "white",
  };

  const focusedStyle = {
    ...baseStyle,
    border: "none",
    outline: "none",
  };

  useEffect(() => {
    const fetchData = async () => {
      if (playerId) {
        try {
          const response = await PlayerProfileById(playerId as string);
          setPlayer(response?.data?.data);
        } catch (error) {
          console.error("Error fetching player data:", error);
        }
      }
      if (id && id !== "new") {
        const response = await fetchDetailChat(id);

        if (response.status) {
          const detailChats = response?.data?.detail_chats;
          const tempChats: ChatItem[] = [];
          detailChats.forEach((detailChat: any) => {
            tempChats.push({
              detail_chat_id: detailChat?.id,
              type: TYPECHAT.user,
              description: detailChat?.query,
              uuid: detailChat?.uuid,
              feedback_like: detailChat?.feedback_like,
              route: "detailChat.route",
              jsonTable: null,
            });

            tempChats.push({
              detail_chat_id: detailChat?.id,
              type: TYPECHAT.ai_answer,
              description: detailChat?.ai_response,
              uuid: detailChat?.uuid,
              feedback_like: detailChat?.feedback_like,
              route: detailChat?.route,
              jsonTable: null,
            });
          });

          setChats(tempChats);
        } else {
          setChats([]);
        }
      }
    };
    fetchData();
  }, [id]);

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };



  const handleSendChat = async (question: string) => {
    setIsActivateAnimation(true)
    if (activeQuestion) {
      console.log("You have a message waiting for a reply");
      return;
    }

    if (!question) {
      console.log("Please, write your question...!");
      return;
    }

    if (question) {
      setQuestion("");
      setInputHeight("56px");
      setActiveQuestion(true);
      setChats([
        ...chats,
        {
          detail_chat_id: null,
          type: TYPECHAT.user,
          description: question,
          uuid: null,
          feedback_like: null,
          route: null,
          jsonTable: null,
        },
        {
          detail_chat_id: null,
          type: TYPECHAT.ai_question,
          description: question,
          uuid: null,
          feedback_like: null,
          route: null,
          jsonTable: null,
        },
      ]);
      const userId = currentUser?.id;
      const chatId = id == "new" ? undefined : id;
      const response = await ChatRotoBot(question, chatId, userId, playerId);
      if (response.status) {
        setRelationQuestion(response);
        const detail_chat = response.data.detail_chat;
        if (detail_chat.chat_id !== id) {
          localStorage.setItem('isAnimation', 'true')
          if (playerId) {
            navigate(`/player/${playerId}/chat/${detail_chat.chat_id}`);
          } else {
            navigate(`/chat/${detail_chat.chat_id}`);
          }
        }
        let aiResponse: string;
        let jsonTable: any | null;

        if (
          response?.data?.metadatas[0] === "general_research_weekly" ||
          response?.data?.metadatas[0] === "general_research_seasonal"
        ) {
          const ai_response_json = JSON.parse(detail_chat?.ai_response);

          aiResponse = ai_response_json?.answer;
          jsonTable = {
            headers: ai_response_json?.header,
            rows: ai_response_json?.rows,
          };
        } else {
          aiResponse = detail_chat.ai_response;
          jsonTable = null;
        }

        setChats((chats) => [
          ...chats,
          {
            detail_chat_id: detail_chat.id,
            type: TYPECHAT.ai_answer,
            description: aiResponse || "Error. Please try again.",
            uuid: detail_chat.uuid,
            feedback_like: detail_chat.feedback_like,
            route: response?.data?.metadatas
              ? response?.data?.metadatas[0]
              : null,
            jsonTable: jsonTable,
          },
        ]);
      } else {
        setChats((chats) => [
          ...chats,
          {
            detail_chat_id: null,
            type: TYPECHAT.ai_answer,
            description: "Error. Please try again.",
            uuid: null,
            feedback_like: null,
            route: null,
            jsonTable: null,
          },
        ]);
      }

      setChats((chats) =>
        chats.filter((chat) => chat.type !== TYPECHAT.ai_question),
      );

      setActiveQuestion(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendChat(question);
    }
  };
  const handleTeamAnalizy = () => {
    navigate("/capabilities");
  };

  const onHandleMarkFeedBackDisLike = async (
    uuid: string | null,
    chat_id: string | null,
    feedback_like: string | null,
  ) => {
    const updatedFeedbackLike =
      feedback_like === "null" || feedback_like === null ? "false" : "null";
    const response = await markFeedBack(
      chat_id,
      uuid,
      updatedFeedbackLike,
      null,
    );

    if (response.status) {
      const newChat = chats.map((item) => {
        if (item.uuid === uuid) {
          return { ...item, feedback_like: updatedFeedbackLike };
        }
        return item;
      });

      setChats(newChat);
    } else {
      console.log("something wrog with RotobotChat, contact support please!!");
    }
  };
  const handleMarkFeedBackLike = async (
    uuid: string | null,
    chat_id: string | null,
    feedback_like: string | null,
  ) => {
    const updatedFeedbackLike =
      feedback_like === "null" || feedback_like === null ? "true" : "null";
    const response = await markFeedBack(
      chat_id,
      uuid,
      updatedFeedbackLike,
      null,
    );

    if (response.status) {
      const newChat = chats.map((item) => {
        if (item.uuid === uuid) {
          return { ...item, feedback_like: updatedFeedbackLike };
        }
        return item;
      });

      setChats(newChat);
    } else {
      console.log("something wrog with RotobotChat, contact support please!!");
    }
  };

  const setRelationQuestion = (response: any) => {
    try {
      const related_questions = JSON.parse(response.data.related_questions);
      console.log(related_questions.question_list);
      const Questions: QuestionItem[] = [];
      related_questions.question_list.forEach(
        (question: string, index: number) => {
          Questions.push({
            id: index,
            title: question,
          });
        },
      );
      setDataItems(Questions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOneQuestionSuggestions = async () => {
    const response = await ChatRotoBotSuggestions();

    if (response.status) {
      setRelationQuestion(response);
    }
  };

  const handleSuggestionClick = (selectedQuestion: string) => {
    handleSendChat(selectedQuestion);
  };

  const handleStopListning = () => {
    stopListening();
    setQuestion(transcript);
    setTranscript("");
  };

  const getPlatform = async (): Promise<string> => {
    const info = await Device.getInfo();
    return info.platform;
  };

  useEffect(() => {
    setQuestion(transcript);
  }, [transcript]);

  useEffect(() => {
    if (question === "") {
      setInputHeight("56px");
    }

    if (!isLoaded.current) {
      fetchOneQuestionSuggestions();
      isLoaded.current = true;
    }
  }, [question]);

  useEffect(() => {
    const fetchPlatform = async () => {
      const platform = await getPlatform();
      setPlatform(platform);
    };

    fetchPlatform();
  }, []);
  const toggleModal = () => setIsModalVisible(!isModalVisible);

  const getPlatformHeight = () => {
    const platform = Capacitor.getPlatform();
    if (platform === "ios" || platform === "android") {
      return "calc(100svh - 135px)";
    }
    return "calc(100svh - 85px)";
  };

  useEffect(() => {
    const randomChance = Math.floor(Math.random() * 8);
    if (randomChance === 7) {
      setIsModalVisible(true);
    }
    fetchCurrentUser();
    const isAnimationLS = localStorage.getItem('isAnimation')
    if(isAnimationLS === 'true') {
      setIsAnimation(true);
      localStorage.removeItem('isAnimation')
    }
  }, []);  

  return (
    <>
      <div
        className={`flex flex-col ${chats.length !== 0 && "overflow-y-auto"}`}
        style={{ height: getPlatformHeight() }}
      >
        <LandingScreenMenu
          isOpen={landingScreenMenu}
          setIsOpen={setLandingScreenMenu}
        />
        {playerId && (
          <div className=" pt-4 flex justify-center items-center px-5 pb-5">
            <PlayersCard
              playerSmallIcon={player?.playerheadshotURL}
              playerName={`${player?.playerFirstName[0]}. ${player?.playerLastName}`}
              playerTeam={player?.playerTeam}
              bgColor={player?.playerTeamShort}
            />
          </div>
        )}

        {chats?.length === 0 && !playerId && !isAnimation ? (
          <div className="flex-1">{!isFocused && <EmptyChat />}</div>
        ) : (
          <ScrollToBottom
            className={`flex flex-row flex-1 overflow-y-auto ${!playerId && "mt-5 py-5"} no-scrollbar scrollToBottom`}
          >
            <div id="body-chat">
              <Messages
                chats={chats}
                onHandleMarkFeedBackLike={handleMarkFeedBackLike}
                onHandleMarkFeedBackDisLike={onHandleMarkFeedBackDisLike}
                isActivateAnimation={isAnimation ? isAnimation : isActivateAnimation}
                setIsActivateAnimation={setIsActivateAnimation}
                userName={fullName}
                regenerateResponse={handleSuggestionClick}
              />
            </div>
          </ScrollToBottom>
        )}

        <div
          id="footer-chat"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            paddingBottom: bottom,
            transition: "all",
            transitionDuration: "400ms",
            width: "100%",
          }}
        >
          <div className="px-5 mt-2 transition-all duration-500">
            {chats?.length === 0 && !isFocused && (
              <div className="">
                <Paragraph
                  text={`Hey, ${firstName}`}
                  className="!text-[24px] md:text-[20px] xl:text-[18px] font-semibold leading-[145%] mt-1"
                  ParagraphType="PragraghSemiBold400"
                  color="textPrimary"
                />
                <Paragraph
                  text={"What would you like to do?"}
                  className="!text-[12px] md:text-[14px] xl:text-base leading-[145%] opacity-70"
                  ParagraphType="PragraghSemiBold400"
                  color="textPrimary"
                />
              </div>
            )}

            <div
              id="suggestions-section"
              className="flex flex-column items-center gap-x-1 mt-2"
            >
              <div id="button-chat" className="pr-3">
                <div
                  id="add-chat"
                  style={{
                    background: Colors.backgroundTertiary,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleTeamAnalizy}
                  className=" w-10 sm:w-12 md:w-14 xl:w-16 h-10 sm:h-12 md:h-14 xl:h-16"
                >
                  <img
                    src={CapabilitiesIcon}
                    alt="Add Chat"
                    className="max-w-70 sm:w-8 md:w-9 xl:w-11 rounded-full"
                  />
                </div>
              </div>

              <SuggestionCarrousel
                items={dataItems}
                onSetQuestion={handleSuggestionClick}
                isAnimation={isActivateAnimation}
              />
            </div>
            <div
              id="chat-section"
              className={`flex flow-row mt-5 mb-3 ${
                inputHeight === "56px" ? "items-center" : "items-start mt-3"
              }`}
            >
              <div id="chat-input" className="flex flex-1">
                <textarea
                  placeholder="Ask me anything..."
                  style={
                    isFocused
                      ? { ...focusedStyle, resize: "none", overflowY: "hidden" }
                      : { ...baseStyle, resize: "none", overflowY: "hidden" }
                  }
                  onFocus={(e) => {
                    setIsFocused(true);
                    setInputHeight(`${e.target.scrollHeight}px`);
                  }}
                  onBlur={() => setIsFocused(false)}
                  onChange={(event) => {
                    setQuestion(event.target.value);
                    setInputHeight(`${event.target.scrollHeight}px`);
                    if (question === "") {
                      setInputHeight("56px");
                    }
                  }}
                  onKeyPress={handleKeyPress}
                  value={question ? question : ""}
                />
                {question ? (
                  <div
                    id="button-chat"
                    onClick={() => handleSendChat(question)}
                    className={`flex pr-4 ${
                      inputHeight === "56px" ? "items-center" : "items-end pb-3"
                    }`}
                    style={{
                      background: Colors.backgroundSecundary,
                      borderTopRightRadius:
                        inputHeight !== "56px" ? "20px" : "50px",
                      borderBottomRightRadius:
                        inputHeight !== "56px" ? "20px" : "50px",
                    }}
                  >
                    <div
                      className="flex justify-center items-center w-10 h-10 rounded-full "
                      style={{
                        background: Colors.backgroundPrimary,
                      }}
                    >
                      <img src={sendChatIcon} alt="" />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex pr-4 ${
                      inputHeight === "56px"
                        ? "items-center"
                        : "items-start pt-3"
                    }`}
                    style={{
                      background: Colors.backgroundSecundary,
                      borderTopRightRadius:
                        inputHeight !== "56px" ? "20px" : "50px",
                      borderBottomRightRadius:
                        inputHeight !== "56px" ? "20px" : "50px",
                    }}
                  >
                    {isActivateAnimation ? (
                      <SkeletonLoader
                        isLoading={isActivateAnimation}
                        className="w-6 h-6 rounded-md"
                        bgColor={Colors.backgroundTertiary}
                      >
                        <div></div>
                      </SkeletonLoader>
                    ) : isListening ? (
                      <div onClick={handleStopListning}>
                        <img src={RecordingIcon} alt="" className="h-6" />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          platform !== "web" && startListening();
                        }}
                      >
                        <img src={MicrophoneIcon} alt="" className="h-6" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CapabilitiesModal isVisible={isModalVisible} onClose={toggleModal} />
      </div>
    </>
  );
};
