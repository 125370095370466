import React from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import { Paragraph } from "../Commons/Paragraph";
import { FAQsExpandableCard } from "./FAQsExpand/FAQsExpand";

const FAQs = () => {
  const navigate = useNavigate();

  const FAQsList = [
    {
      title: "How do I access the chatbot?",
      description: `You can access the RotoBot chatbot directly from the app's main screen. 
      Simply tap on the chatbot icon to start interacting. The chatbot is integrated across various sections of the app, 
      allowing you to ask questions and receive tailored advice wherever you are in the app.`,
    },
    {
      title: "What kind of questions can I ask the chatbot?",
      description: `You can ask the RotoBot chatbot a wide range of questions related to fantasy football. 
      For example, you can ask for sit/start decisions, trade advice, player analysis, or even to evaluate your entire team. 
      The chatbot can also provide waiver wire suggestions, game predictions, and insights on player performance.`,
    },
    {
      title: "Do I need to sync my league to use the chatbot?",
      description: `While you can use the RotoBot chatbot without syncing your league, syncing your league 
      allows the chatbot to provide more personalized and accurate advice based on your specific team, league settings, and opponents.`,
    },
    {
      title: "How often are player insights updated?",
      description: `Player insights in RotoBot are updated weekly. This ensures that you have up-to-date information 
      when making decisions about your fantasy football team each week.`,
    },
    {
      title: "What are the scores in player profiles?",
      description: `The scores in RotoBot’s player profiles are key metrics that help you evaluate a player’s value in fantasy football. These include:
      • Performance Score: This metric assesses a player’s recent performance in games, factoring in their statistics like yards, touchdowns, receptions, and more.
      • Usage Score: The Usage Score indicates how frequently a player is involved in their team’s offensive plays. It takes into account factors like the number of touches, target share, and snap counts.
      • Team Score: The Team Score reflects the overall strength and effectiveness of the player’s team, particularly in their ability to create scoring opportunities.`,
    },
    {
      title: "Can I access additional player information beyond the scores?",
      description: `Yes, RotoBot provides in-depth player profiles that include game logs, season and weekly outlooks, injury updates, and more. 
      You can delve deeper into each player's stats to make more informed decisions.`,
    },
    {
      title: "How can I use the rankings section to improve my team?",
      description: `The rankings section in RotoBot allows you to filter players by position and see weekly and season rankings. 
      You can use these rankings to identify top-performing players, sleepers, and waiver wire targets to improve your team's overall strength.`,
    },
    {
      title: "Is there a cost to use the app and chatbot?",
      description: `Yes, there is a cost associated with using the RotoBot chatbot. 
      While the app itself might offer some basic features for free, access to the chatbot and other premium features typically requires a subscription or in-app purchase.`,
    },
    {
      title: "How can I contact support if I have further questions?",
      description: `If you need additional help or have further questions, you can contact our support team directly through the app by navigating to the “Help & Support” section. 
      From there, you can send us a message or submit a support request. Alternatively, you can reach out via email at [Support Email Address], and our team will get back to you as soon as possible.`,
    },
  ];

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
          style={{ background: Colors.backgroundSecundary }}
          onClick={() => navigate("/home")}
        >
          <img src={ArrowBackIcon} alt="Back" />
        </div>
        <Paragraph
          text="FAQs"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>

      <div
        className="mt-8 rounded-[18px] p-4"
        style={{
          backgroundColor: Colors.backgroundSecundary,
        }}
      >
        {FAQsList.map((item, index) => (
          <div key={index}>
            <div className="-mx-4">
              <FAQsExpandableCard
                header={
                  <div className="w-9/12">
                    <Paragraph
                      text={item.title}
                      props={{
                        fontSize: "15px",
                        fontWeight: 600,
                        letterSpacing: "-0.5px",
                      }}
                      ParagraphType="Pragragh22"
                      color="textPrimary"
                    />
                  </div>
                }
              >
                <div className="mt-5">
                  <Paragraph
                    text={item.description}
                    props={{
                      fontSize: "13px",
                      letterSpacing: "-0.5px",
                    }}
                    ParagraphType="Pragragh22"
                    color="textPrimary"
                  />
                </div>
              </FAQsExpandableCard>
            </div>
            <div
              className="w-full h-[1px] my-1"
              style={{
                backgroundColor: Colors.backgroundSecundary,
              }}
            ></div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default FAQs;
