import React from "react";
import { Colors } from "../../Commons/Colors/Colors";
import { snakeToTitleCase } from "../../../lib/utils";

const MAX_COLUMNS = 4;

const TableComponent = ({ headers, rows }: { headers: any[]; rows: any[] }) => {
  console.log("headers", headers);
  console.log("rows", rows);

  const textSizeClass = headers.length > 3 ? "text-xs sm:text-sm" : "text-sm";

  return (
    <div className="overflow-x-auto my-4">
      <table
        className={`w-full bg-opacity-10 bg-white rounded-lg ${textSizeClass}`}
      >
        <thead style={{ background: Colors.backgroundSecundary }}>
          <tr>
            {headers.slice(0, MAX_COLUMNS).map((header, index) => (
              <th
                key={index}
                className={`text-white text-center py-2 px-4 border-b border-gray-600 ${
                  index === 0 ? "rounded-tl-lg" : ""
                } ${index === headers.slice(0, MAX_COLUMNS).length - 1 ? "rounded-tr-lg" : ""}`}
              >
                {snakeToTitleCase(header)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex} className="border-b border-gray-600">
              {row.slice(0, MAX_COLUMNS).map((cell: any, cellIndex: any) => (
                <td
                  key={cellIndex}
                  className="text-center py-2 px-4 border-r border-gray-600 text-white"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
