import { Navigate } from "react-router-dom";
import { PrivateRouteProps } from "./type";
import { useSupabaseAuth } from "../Context/AuthContext/AuthContext";

const isAuthenticated = (user: any): boolean => {

  console.log("public route check if user is not authenticated",user);
  
  if (user) {
    return false;
  }
  return true;
};


export const PublicRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { useAuthStore } = useSupabaseAuth();
  const user = useAuthStore((state: any) => state.user);
  return isAuthenticated(user) ? (
    <>{children}</>
  ) : (
    <Navigate to="/home" replace />
  );
};
