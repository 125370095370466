import React, { useEffect, useState } from "react";
import CircularProgressBar from "../../Commons/CircularProgressBar/CircularProgressBar";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import StatsSection from "../StatItem/StatItem";

interface Props {
  player: any;
}

const PlayerScores = ({ player }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCard, setSelectedCard] = useState("Performance");
  const [selectedCardData, setSelectedCarddata] = useState([] as any);

  useEffect(() => {
    if (player) {
      const filterData =
        player?.playerPerformanceScores?.performanceCards.filter(
          (item: any) => {
            return item.cardTitle === selectedCard;
          },
        );
      setSelectedCarddata(filterData);
    }
  }, [player, selectedCard]);

  console.log("selectedCardData", selectedCardData[0]);

  return (
    <div>
      <div
        className={`flex gap-2 overflow-x-auto mb-8`}
        style={{
          justifyContent: "space-between",
        }}
      >
        {player?.playerPerformanceScores?.performanceCards.length > 0
          ? player?.playerPerformanceScores?.performanceCards?.map(
              (card: any, index: number) => {
                return (
                  <div
                    className="shrink-0 p-3 sm:p-4 md:p-4 lg:p-5 rounded-[8px] sm:rounded-[12px] md:rounded-[21pxpx] w-[105px] sm:w-[150px] md:w-[190px] lg:w-[266.095px] cursor-pointer"
                    style={{
                      backgroundColor:
                        selectedIndex === index
                          ? Colors.backgroundSecundary
                          : "",
                      opacity: selectedIndex === index ? 1 : 0.4,
                    }}
                    key={index}
                    onClick={() => {
                      setSelectedIndex(index);
                      setSelectedCard(card.cardTitle);
                    }}
                  >
                    <Paragraph
                      text={card.cardTitle}
                      className="mb-4 sm:mb-5 md:mb-6 lg:mb-7 text-xs sm:text-base md:text-xl lg:text-2xl text-center"
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                    />
                    <CircularProgressBar
                      percentage={card.score}
                      pathColor={
                        card.score > 85
                          ? "#16C265"
                          : card.score < 85 && card.score > 75
                            ? "#DBFF00"
                            : card.score < 74
                              ? "#F11E84"
                              : ""
                      }
                      className="sm:w-[100px] md:w-[140px] lg:w-[170px] sm:h-[100px] md:h-[140px] lg:h-[170px] m-auto"
                    />
                  </div>
                );
              },
            )
          : ["Performance", "Usage", "Team"].map((card: any, index: number) => {
              return (
                <div
                  className="p-3 rounded-[8px] w-[105px]"
                  style={{
                    backgroundColor:
                      selectedIndex === index ? Colors.backgroundSecundary : "",
                    opacity: selectedIndex === index ? 1 : 0.4,
                  }}
                  key={index}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                >
                  <Paragraph
                    text={card}
                    className="mb-2 text-xs text-center"
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                  />
                  <div className="text-[40px] text-center">--</div>
                </div>
              );
            })}
        {player?.playerPerformanceScores?.performanceCards.length < 3 && (
          <div className="w-[105px] sm:w-[150px] md:w-[190px] lg:w-[266.095px] " />
        )}
      </div>
      <div className="md:mt-6">
        <Paragraph
          text={selectedCardData[0]?.blurbShort}
          className="text-[10.5px] sm:text-xs md:text-base lg:text-[18.61px] opacity-70"
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
        <Paragraph
          text={selectedCardData[0]?.blurbLong}
          className="text-[10.5px] sm:text-xs md:text-base lg:text-[18.61px] opacity-70 mt-2"
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
        <div className="mt-6 grid lg:grid-cols-2 lg:gap-x-10 md:gap-x-5">
          {selectedCardData[0]?.statCategories.map(
            (category: any, index: number) => (
              <StatsSection key={index} {...category} />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerScores;
